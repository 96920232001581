import Footer from '../../components/footer';
import MainMenu from '../../components/menu';
import { ProjectGrid } from '../../components/ProjectGrid';
import { PageTitle } from '../../components/typography';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Title = styled(PageTitle)``;

const Projects = () => {
  const { t } = useTranslation();
  return (
    <>
      <MainMenu fixed />
      <Title>{t('projects.titleShort')}</Title>
      <ProjectGrid />
      <Footer />
    </>
  );
};

export default Projects;
