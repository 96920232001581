import styled from 'styled-components';
import { Icon as BaseIcon } from '../../components/Icon';
import { H2, Text } from '../../components/typography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: stretch;
`;

const Header = styled.div`
  flex: 0 1 50%;
  display: flex;
  flex-direction: ${({ wide }) => (wide ? 'row' : 'column')};
  align-items: ${({ wide }) => (wide ? 'flex-end' : 'center')};
`;

const IconContainer = styled.div`
  flex-basis: ${({ wide }) => (wide ? '30%' : '50%')};
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.$tablet}) {
    width: 8em;
    height: ${({ wide }) => (wide ? '' : '7em')};
  }
  @media (max-width: ${({ theme }) => theme.$phone}) {
    width: 5em;
  }
`;
const Icon = styled(BaseIcon)`
  color: ${({ theme }) => theme.$iconFaceColor};
  vertical-align: text-bottom;
  margin: 0rem 1rem;
  height: ${({ wide }) => (wide ? '12em' : '4em')};
  @media (max-width: ${({ theme }) => theme.$tablet}) {
    height: ${({ wide }) => (wide ? '7em' : '5em')};
  }
  @media (max-width: ${({ theme }) => theme.$phone}) {
    height: ${({ wide }) => (wide ? '6em' : '8em')};
  }
`;
const Title = styled(H2)`
  display: inline-block;
  color: ${({ theme }) => theme.$primary};
  font-family: ${({ theme }) => theme.$fontBold};
  font-size: ${({ wide }) => (wide ? '4.2em' : '2.5em')};
  text-align: ${({ wide }) => (wide ? 'left' : 'center')};
  flex-basis: ${({ wide }) => (wide ? '120%' : '')};
  line-height: ${({ wide }) => (wide ? '1em' : '1.5em')};
  @media (max-width: ${({ theme }) => theme.$tablet}) {
    font-size: ${({ wide }) => (wide ? '2.5em' : '1.5em')};
  }
`;
const Description = styled(Text)`
  padding: 1em;
  text-align: ${({ wide }) => (wide ? 'left' : 'center')};
  justify-self: center;
  align-self: center;
  flex-basis: 50%;
  flex-grow: 1;

  @media (max-width: ${({ theme }) => theme.$tablet}) {
    padding: 0em;
  }
`;

export const Card = ({ title, icon, description, wide, ...props }) => (
  <Container {...props}>
    <Header wide={wide}>
      <IconContainer>
        <Icon wide={wide} icon={icon} />
      </IconContainer>
      <Title wide={wide}>{title}</Title>
    </Header>
    <Description wide={wide}>{description}</Description>
  </Container>
);
