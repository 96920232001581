export const logo = ({ ...props }) => (
  <svg
    {...props}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 45 45"
  >
    <g>
      <path
        fill="currentColor"
        d="M35.8,19.4v6.2h2.7c-1.5,7.5-8.1,13.2-16,13.2c-9,0-16.3-7.3-16.3-16.3c0-9,7.3-16.3,16.3-16.3
		c5.5,0,10.7,2.8,13.7,7.5l5.2-3.4C37.2,3.8,30.2,0,22.5,0C10.1,0,0,10.1,0,22.5C0,34.9,10.1,45,22.5,45C34.9,45,45,34.9,45,22.5
		v-3.1H35.8z"
      />
      <path
        fill="currentColor"
        d="M22.5,25.6h5.9c-1.1,2.1-3.3,3.6-5.9,3.6c-3.7,0-6.7-3-6.7-6.7c0-3.7,3-6.7,6.7-6.7c2.2,0,4.3,1.1,5.5,2.9
		l5.1-3.5c-2.4-3.5-6.4-5.6-10.7-5.6c-7.1,0-12.9,5.8-12.9,12.9c0,7.1,5.8,12.9,12.9,12.9c5.9,0,10.9-4,12.4-9.4v-6.5H22.5V25.6z"
      />
    </g>
  </svg>
);
