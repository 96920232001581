export const hotel_resort = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="229.959"
    height="211.516"
    viewBox="0 0 229.959 211.516"
  >
    <g id="Group_128" data-name="Group 128" transform="translate(1.385 1.171)">
      <path
        id="Path_8449"
        data-name="Path 8449"
        d="M215.98,65.817a6.482,6.482,0,0,1-7.137-.036,13.827,13.827,0,0,0-15.2.032,6.5,6.5,0,0,1-7.13-.029,13.834,13.834,0,0,0-15.2.022,6.477,6.477,0,0,1-7.126-.022,13.833,13.833,0,0,0-15.2.025,6.478,6.478,0,0,1-7.123-.022,13.84,13.84,0,0,0-15.2.018,6.445,6.445,0,0,1-7.119-.018,13.822,13.822,0,0,0-15.19.018,6.484,6.484,0,0,1-7.126-.018,13.824,13.824,0,0,0-15.183.014,6.465,6.465,0,0,1-7.011.057,1.183,1.183,0,0,0-.111-.072,13.81,13.81,0,0,0-15.183.014,6.457,6.457,0,0,1-7.094,0,13.8,13.8,0,0,0-15.194,0,6.465,6.465,0,0,1-7.011.057,1.182,1.182,0,0,0-.111-.072,13.809,13.809,0,0,0-15.186.018,6.463,6.463,0,0,1-7.1,0L4.005,71.955A13.828,13.828,0,0,0,19.2,71.937a6.452,6.452,0,0,1,7.008-.054,1.179,1.179,0,0,0,.111.072A13.818,13.818,0,0,0,41.5,71.944a6.462,6.462,0,0,1,7.1,0,13.816,13.816,0,0,0,15.186-.007,6.458,6.458,0,0,1,7.011-.057,1.178,1.178,0,0,0,.111.072,13.824,13.824,0,0,0,15.183-.014,6.464,6.464,0,0,1,7.123.014,13.828,13.828,0,0,0,15.194-.018,6.471,6.471,0,0,1,7.126.022,13.8,13.8,0,0,0,15.19-.022,6.5,6.5,0,0,1,7.133.022,13.833,13.833,0,0,0,15.2-.025,6.47,6.47,0,0,1,7.13.025,13.8,13.8,0,0,0,15.186-.022,6.5,6.5,0,0,1,7.141.025,13.827,13.827,0,0,0,15.2-.032,6.5,6.5,0,0,1,7.144.036,13.836,13.836,0,0,0,15.222-.043,6.455,6.455,0,0,1,7.076,0l4.03-6.156A13.844,13.844,0,0,0,215.98,65.817Z"
        transform="translate(-4.005 134.884)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <path
        id="Path_8450"
        data-name="Path 8450"
        d="M225.585,178.425a3.676,3.676,0,0,0,3.678-3.678V139.913a3.676,3.676,0,0,0-3.678-3.678H185.047V75.493l5.851,4.985,4.77-5.6-75.7-64.431a3.678,3.678,0,0,0-4.77,0L39.547,74.882l4.77,5.6,5.851-4.985v60.742H9.627a3.676,3.676,0,0,0-3.678,3.678v34.834a3.676,3.676,0,0,0,3.678,3.678H32.862v17.293a6.436,6.436,0,0,1-2.532-.921,13.815,13.815,0,0,0-15.194.014,6.45,6.45,0,0,1-7.094,0L4.005,200.96a13.821,13.821,0,0,0,15.2-.014,6.446,6.446,0,0,1,7.112.014,13.817,13.817,0,0,0,15.183-.011,6.456,6.456,0,0,1,7.1,0,13.809,13.809,0,0,0,15.19-.007,6.458,6.458,0,0,1,7.119.014,13.816,13.816,0,0,0,15.186-.014,6.464,6.464,0,0,1,7.123.014,13.815,13.815,0,0,0,15.194-.018,6.484,6.484,0,0,1,7.126.022,13.809,13.809,0,0,0,15.19-.022,6.5,6.5,0,0,1,7.133.022,13.846,13.846,0,0,0,15.2-.025,6.47,6.47,0,0,1,7.13.025,13.8,13.8,0,0,0,15.186-.022,6.489,6.489,0,0,1,7.137.025,13.819,13.819,0,0,0,15.2-.032,6.5,6.5,0,0,1,7.144.036,13.737,13.737,0,0,0,7.554,2.259,13.958,13.958,0,0,0,7.665-2.3,6.467,6.467,0,0,1,7.083,0l4.023-6.156a13.845,13.845,0,0,0-15.212.054,6.468,6.468,0,0,1-7.137-.04,13.729,13.729,0,0,0-6.494-2.184V178.425ZM57.524,69.229l60.063-51.145,60.1,51.145v67.006H136.973V110.223a19.366,19.366,0,0,0-38.731,0v26.012H57.524Zm72.093,67.006H105.6V110.223a12.009,12.009,0,0,1,24.019,0ZM13.3,143.591h208.6v27.478H13.3Zm181.688,50.474a14.043,14.043,0,0,0-1.354.753,6.464,6.464,0,0,1-7.126-.029,13.815,13.815,0,0,0-15.194.025,6.477,6.477,0,0,1-7.13-.025,13.806,13.806,0,0,0-15.2.029,6.5,6.5,0,0,1-7.13-.025,13.828,13.828,0,0,0-15.194.022,6.484,6.484,0,0,1-7.126-.022,13.815,13.815,0,0,0-15.19.018,6.458,6.458,0,0,1-7.119-.014,13.816,13.816,0,0,0-15.186.011,6.472,6.472,0,0,1-7.119-.011,13.823,13.823,0,0,0-15.186.007,6.437,6.437,0,0,1-7.094,0,13.807,13.807,0,0,0-12.424-1.359v-15.02H194.993Z"
        transform="translate(-4.005 -9.573)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <path
        id="Path_8451"
        data-name="Path 8451"
        d="M50.175,59.968A21.009,21.009,0,1,0,29.184,38.941,21.033,21.033,0,0,0,50.175,59.968Zm0-34.661A13.653,13.653,0,1,1,36.54,38.941,13.651,13.651,0,0,1,50.175,25.307Z"
        transform="translate(63.425 12.862)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <path
        id="Path_8452"
        data-name="Path 8452"
        d="M33.5,35.209A13.361,13.361,0,0,0,20.153,48.553v9.7a3.676,3.676,0,0,0,3.678,3.678H43.159a3.676,3.676,0,0,0,3.678-3.678v-9.7A13.358,13.358,0,0,0,33.5,35.209Zm5.984,19.364H27.509v-6.02a5.986,5.986,0,1,1,11.972,0Z"
        transform="translate(39.239 59.082)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <path
        id="Path_8453"
        data-name="Path 8453"
        d="M55.718,35.209A13.361,13.361,0,0,0,42.375,48.553v9.7a3.676,3.676,0,0,0,3.678,3.678H65.384a3.676,3.676,0,0,0,3.678-3.678v-9.7A13.361,13.361,0,0,0,55.718,35.209Zm5.988,19.364H49.731v-6.02a5.988,5.988,0,0,1,11.975,0Z"
        transform="translate(98.75 59.082)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_330"
        data-name="Rectangle 330"
        width="7.198"
        height="7.356"
        transform="translate(57.412 81.286)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_331"
        data-name="Rectangle 331"
        width="7.198"
        height="7.356"
        transform="translate(69.136 81.286)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_332"
        data-name="Rectangle 332"
        width="7.198"
        height="7.356"
        transform="translate(80.857 81.286)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_333"
        data-name="Rectangle 333"
        width="7.202"
        height="7.356"
        transform="translate(139.146 81.286)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_334"
        data-name="Rectangle 334"
        width="7.198"
        height="7.356"
        transform="translate(150.87 81.286)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_335"
        data-name="Rectangle 335"
        width="7.202"
        height="7.356"
        transform="translate(162.59 81.286)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_344"
        data-name="Rectangle 344"
        width="25.79"
        height="7.356"
        transform="translate(21.928 144.079)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_345"
        data-name="Rectangle 345"
        width="25.79"
        height="7.356"
        transform="translate(61.317 144.079)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_346"
        data-name="Rectangle 346"
        width="25.79"
        height="7.356"
        transform="translate(100.705 144.079)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_347"
        data-name="Rectangle 347"
        width="25.793"
        height="7.356"
        transform="translate(140.094 144.079)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_348"
        data-name="Rectangle 348"
        width="25.79"
        height="7.356"
        transform="translate(179.486 144.079)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
    </g>
  </svg>
);
