import Swal from 'sweetalert2';
import i18n from '../i18n';

export const messageError = (message) => {
  Swal.fire({
    text: message,
    icon: 'error',
    confirmButtonColor: '#B1C91E',
    confirmButtonText: i18n.t('close')
  });
};

export const messageSuccess = (message) => {
  Swal.fire({
    text: message,
    icon: 'success',
    confirmButtonColor: '#B1C91E',
    confirmButtonText: i18n.t('close')
  });
};

export const messageWarning = (message) => {
  Swal.fire({
    text: message,
    icon: 'warning',
    confirmButtonColor: '#B1C91E',
    confirmButtonText: i18n.t('close')
  });
};

export const messageConfirm = (message) => {
  return Swal.fire({
    text: '¿Seguro que desea ' + message + '?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#B1C91E',
    confirmButtonText: i18n.t('sure'),
    cancelButtonColor: '#31332D',
    cancelButtonText: i18n.t('cancel')
  }).then((result) => result.value);
};

export const messageConfirmText = (message) => {
  return Swal.fire({
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#B1C91E',
    confirmButtonText: i18n.t('yes'),
    cancelButtonColor: '#31332D',
    cancelButtonText: i18n.t('no')
  }).then((result) => result.value);
};

export const messageSuccessDownload = (message) => {
  return Swal.fire({
    text: message,
    icon: 'success',
    showCancelButton: true,
    confirmButtonColor: '#B1C91E',
    confirmButtonText: i18n.t('close'),
    cancelButtonColor: '#31332D',
    cancelButtonText: i18n.t('download')
  }).then((result) => {
    if (result.value) {
      return true;
    }});
};
