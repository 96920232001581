import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: $mainFontBold;
  font-size: 10rem;
  @media (max-width: ${({ theme }) => theme.$tablet}) {
    font-size: 5rem;
  }
`;

export const PageTitle = styled(H1)`
  display: inline-block;
  color: ${({ theme }) => theme.$primary};
  padding-top: 16rem;
  padding-bottom: 1rem;
  margin-bottom: 9rem;
  margin-left: 9rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.$primary};
    height: 0.75rem;
    width: 50%;
    bottom: 0;
    left: 0;
  }
  @media (max-width: ${({ theme }) => theme.$tablet}) {
    font-size: 6rem;
    padding-top: 4rem;
    margin-left: 1rem;
    margin-bottom: 5rem;
  }
`;

export const H2 = styled.h2`
  font-size: 3.6rem;
  @media (max-width: ${({ theme }) => theme.$tablet}) {
    font-size: 2rem;
    font-weight: bold;
  }
`;

export const H3 = styled.h3`
  font-size: 3rem;
  @media (max-width: ${({ theme }) => theme.$tablet}) {
    font-size: 1.6rem;
  }
`;

export const H4 = styled.h4`
  font-size: 3rem;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.$foreground};
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.1em;
  @media (max-width: ${({ theme }) => theme.$tablet}) {
    font-size: 1.6rem;
  }
`;
