import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { H2, H3 } from '../../components/typography';
import projects from '../../data/projects';
import { useHistory } from 'react-router-dom';
import { assetUrl } from '../../utils/assets';

const GridItemImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(${({ src }) => src});
  background-size: cover;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
  z-index: -1;
  @media (max-width: ${({ theme }) => theme.$phone}) {
    background-position: bottom center;
  }
`;

const Content = styled.div`
  position: absolute;
  bottom: 3rem;
  left: 3rem;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  color: white;

  @media (hover: none) {
    opacity: 1;
  }
  @media (max-width: ${({ theme }) => theme.$phone}) {
    right: 3rem;
  }
`;
const Title = styled(H2)`
  text-shadow: 1px 1px 10px ${({ theme }) => theme.$black};
  @media (max-width: ${({ theme }) => theme.$phone}) {
    text-align: right;
  }
`;
const Subtitle = styled(H3)`
  text-shadow: 1px 1px 10px ${({ theme }) => theme.$black};
  @media (max-width: ${({ theme }) => theme.$phone}) {
    text-align: right;
  }
`;

const Grid = styled.div`
  width: 100vw;
  height: 3000px;
  display: grid;
  gap: 10px;
  grid-template: repeat(23, 1fr) / repeat(12, 1fr);
  grid-auto-flow: row;

  @media (max-width: ${({ theme }) => theme.$phone}) {
    height: 300em;
    grid-template: repeat(15, 1fr) / repeat(1, 1fr);
  }
`;

const GridItem = styled.div`
  position: relative;
  overflow: hidden;
  grid-column: span ${({ span }) => span[0]};
  grid-row: span ${({ span }) => span[1]};
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.$phone}) {
    grid-column: initial;
    grid-row: initial;
  }

  &:hover ${GridItemImage} {
    transform: scale(1.2);
  }
  &:hover ${Content} {
    opacity: 1;
  }
`;

const Item = ({ slug, src, title, subtitle, ...props }) => {
  const history = useHistory();
  const handleOnClick = () => history.push(`/project/${slug}`);

  return (
    <GridItem {...props} onClick={handleOnClick}>
      <GridItemImage src={assetUrl(src)} />
      <Content>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Content>
    </GridItem>
  );
};

export const ProjectGrid = () => {
  const { t } = useTranslation();

  return (
    <Grid>
      {projects
        .sort((a, b) => a?.grid?.order - b?.grid?.order)
        .map(({ name, slug, grid: { image, span }, classification }, idx) => (
          <Item
            key={idx}
            src={image}
            title={name}
            slug={slug}
            subtitle={classification
              .map((c) => t(`project.type.${c}`))
              .join(', ')}
            span={span}
          />
        ))}
    </Grid>
  );
};
