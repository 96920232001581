export const toggle = ({ props, dark }) => (
  <svg
    {...props}
    id="Component_49_2"
    width="107"
    height="51"
    viewBox="0 0 107 51"
  >
    <defs>
      <filter id="Rectangle_255">
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.161" result="color" />
        <feComposite operator="out" in="SourceGraphic" in2="blur" />
        <feComposite operator="in" in="color" />
        <feComposite operator="in" in2="SourceGraphic" />
      </filter>
      <filter
        id="Ellipse_2"
        x="12"
        y="2"
        width="90"
        height="49"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="4" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur-2" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-type="innerShadowGroup">
      <rect
        id="Rectangle_255-2"
        data-name="Rectangle 255"
        width="107"
        height="45"
        rx="22.5"
        fill="#b2d234"
      />
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_255)">
        <rect
          id="Rectangle_255-3"
          data-name="Rectangle 255"
          width="107"
          height="45"
          rx="22.5"
          fill="#fff"
        />
      </g>
    </g>
    <g id="noun_day_1327883" transform="translate(15.601 7.781)">
      <g id="Group_69" data-name="Group 69" transform="translate(0 0)">
        <g id="Group_68" data-name="Group 68">
          <circle
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="6.408"
            cy="6.408"
            r="6.408"
            transform="translate(8.239 8.239)"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
          />
          <path
            id="Path_8383"
            data-name="Path 8383"
            d="M4.577,0H0"
            transform="translate(14.647) rotate(90)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="2"
            fillRule="evenodd"
          />
          <path
            id="Path_8384"
            data-name="Path 8384"
            d="M4.577,0H0"
            transform="translate(14.647 24.716) rotate(90)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="2"
            fillRule="evenodd"
          />
          <path
            id="Path_8385"
            data-name="Path 8385"
            d="M4.577,0H0"
            transform="translate(29.293 14.647) rotate(180)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="2"
            fillRule="evenodd"
          />
          <path
            id="Path_8386"
            data-name="Path 8386"
            d="M4.577,0H0"
            transform="translate(4.577 14.647) rotate(180)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="2"
            fillRule="evenodd"
          />
          <path
            id="Path_8387"
            data-name="Path 8387"
            d="M4.577,0H0"
            transform="translate(25.003 4.29) rotate(135)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="2"
            fillRule="evenodd"
          />
          <path
            id="Path_8388"
            data-name="Path 8388"
            d="M4.577,0H0"
            transform="translate(7.526 21.767) rotate(135)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="2"
            fillRule="evenodd"
          />
          <path
            id="Path_8389"
            data-name="Path 8389"
            d="M4.577,0H0"
            transform="translate(25.003 25.003) rotate(-135)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="2"
            fillRule="evenodd"
          />
          <path
            id="Path_8390"
            data-name="Path 8390"
            d="M4.577,0H0"
            transform="translate(7.526 7.526) rotate(-135)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="2"
            fillRule="evenodd"
          />
        </g>
      </g>
    </g>
    <g id="noun_Moon_4183424" transform="translate(66.006 8.143)">
      <g id="_04_moon" data-name="04 moon" transform="translate(2.003 2)">
        <path
          id="Path_8382"
          data-name="Path 8382"
          d="M22.434,20.448a10,10,0,0,1-10.075-5.21,10.989,10.989,0,0,1,.725-11.726.916.916,0,0,0,.061-.979A.833.833,0,0,0,12.3,2.1a12.1,12.1,0,0,0-10.2,10.634,12.45,12.45,0,0,0,7.2,13.04A11.507,11.507,0,0,0,23.215,21.86a.916.916,0,0,0,.061-.978.834.834,0,0,0-.84-.435ZM18.887,23.53a9.761,9.761,0,0,1-10.711-.374,10.788,10.788,0,0,1-4.4-10.217A10.488,10.488,0,0,1,10.613,4.31,12.851,12.851,0,0,0,10.9,16.123a11.766,11.766,0,0,0,9.644,6.164,10.136,10.136,0,0,1-1.652,1.243ZM20.576,4.648a.864.864,0,0,1,.844-.883h.844V2.883a.845.845,0,1,1,1.689,0v.883H24.8a.884.884,0,0,1,0,1.765h-.844v.883a.845.845,0,1,1-1.689,0V5.531H21.42A.864.864,0,0,1,20.576,4.648Zm-6.755,5.3a.864.864,0,0,1,.844-.883h.844V8.179a.845.845,0,1,1,1.689,0v.883h.844a.884.884,0,0,1,0,1.765H17.2v.883a.845.845,0,1,1-1.689,0v-.883h-.844A.864.864,0,0,1,13.821,9.944Zm11.821,6.179a.864.864,0,0,1-.844.883h-.844v.883a.845.845,0,1,1-1.689,0v-.883H21.42a.884.884,0,0,1,0-1.765h.844v-.883a.845.845,0,1,1,1.689,0v.883H24.8A.864.864,0,0,1,25.642,16.123Z"
          transform="translate(-2.003 -2)"
          fill="#171717"
        />
      </g>
    </g>
    <g
      transform="matrix(1, 0, 0, 1, 0, 0)"
      filter="url(#Ellipse_2)"
      style={{ transition: 'all 0.3ms ease-in-out' }}
    >
      <ellipse
        id="Ellipse_2-2"
        data-name="Ellipse 2"
        cx="15"
        cy="15"
        rx="16"
        ry="16"
        style={{
          transform: dark ? 'translate(66px, 7px)' : 'translate(15px, 7px)',
          fill: dark ? '#fff' : '#1d1d1d',
          transition: 'all 0.3s ease-in-out'
        }}
      />
    </g>
  </svg>
);
