// import Flickity from 'react-flickity-component';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LinkButton as LinkBase } from '../../../components/Link';
import projects from '../../../data/projects.js';
import { assetUrl } from '../../../utils/assets';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef, useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);

const LinkButton = styled(LinkBase)`
  margin: 0 0 4rem 3rem;
  @media (max-width: ${({ theme }) => theme.$phone}) {
    margin: 0 0 4rem 3rem;
  }
`;

const Projects = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const slider = useRef();
  const projectsElement = useRef();
  let flkty = null;

  const handleOnClick = (slug) => history.push(`/project/${slug}`);

  const dataSlider = projects
    .filter(({ front }) => front?.visible)
    .sort((a, b) => a.front.order - b.front.order);

  useEffect(() => {
    const slides = slider.current.querySelectorAll('.slide');
    const totalSlideAmount = 100 * (slides.length - 3) + 70 * 2;
    const snapPositions = [0, 48, 130, 240, 392].map(
      (i) => i / (totalSlideAmount + 100)
    );
    const scrollTrigger = {
      pin: true,
      trigger: slider.current,
      scrub: 1,
      end: '1000%',
      snap: snapPositions
    };

    if (!window.matchMedia('(max-width: 768px)').matches) {
      scrollTrigger['start'] = '-=62px';
      scrollTrigger['end'] = '200%';
    }

    gsap.to(slider.current, {
      xPercent: -totalSlideAmount,
      scrollTrigger
    });

    // ScrollTrigger.create({ snap: 1 / sections.length });
  }, []);

  return (
    <section id="projects" className="wrap-projects" ref={projectsElement}>
      <div className="slider-desk" ref={slider}>
        <div className="firts-slider slide">
          <h1>{t('projects.title')}</h1>
          <p>
            {t('projects.swipeMsg')}
            <img
              src={assetUrl('/arrow-next.svg')}
              onClick={() => {
                flkty && flkty.next();
              }}
            />
          </p>
          <LinkButton to="/portfolio">{t('projects.portfolio')}</LinkButton>
        </div>
        {dataSlider.map(
          ({ front: { image }, name, classification, slug }, index) => (
            <div
              key={index}
              className="project-slider slider-show slide"
              onClick={() => handleOnClick(slug)}
              style={{
                backgroundImage: `url(${assetUrl(image)})`,
                backgroundSize: 'cover'
              }}
            >
              <div className="project-content">
                <h2>{name}</h2>
                <p>{t(`project.type.${classification?.[0]}`)}</p>
              </div>
            </div>
          )
        )}
        <div className="project-slider last-slider slide">
          <LinkButton to="/portfolio">{t('projects.portfolio')}</LinkButton>
        </div>
      </div>
    </section>
  );
};

export default Projects;
