export const free_zone_industrial = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="237.729"
    height="206.285"
    viewBox="0 0 237.729 206.285"
  >
    <g
      id="noun_Industrial_Building_3995416"
      data-name="noun_Industrial Building_3995416"
      transform="translate(1.5 1.5)"
    >
      <g id="Group_86" data-name="Group 86" transform="translate(0 0)">
        <path
          id="Path_8434"
          data-name="Path 8434"
          d="M67.3,414.858H88.544a3.055,3.055,0,0,0,3.051-3.051V382.313a3.055,3.055,0,0,0-3.051-3.051H67.3a3.055,3.055,0,0,0-3.051,3.051v29.493A3.055,3.055,0,0,0,67.3,414.858Zm3.051-29.494H85.492v23.391H70.347Z"
          transform="translate(-40.839 -233.178)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_8435"
          data-name="Path 8435"
          d="M175.347,446.443h14.572a3.055,3.055,0,0,0,3.051-3.051V428.82a3.055,3.055,0,0,0-3.051-3.051H175.347a3.055,3.055,0,0,0-3.051,3.051v14.572A3.055,3.055,0,0,0,175.347,446.443Zm3.051-14.572h8.469v8.469H178.4Z"
          transform="translate(-95.946 -256.897)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_8436"
          data-name="Path 8436"
          d="M175.347,397.885h14.572a3.055,3.055,0,0,0,3.051-3.051V380.262a3.055,3.055,0,0,0-3.051-3.051H175.347a3.055,3.055,0,0,0-3.051,3.051v14.572A3.055,3.055,0,0,0,175.347,397.885Zm3.051-14.572h8.469v8.47H178.4Z"
          transform="translate(-95.946 -232.131)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_8437"
          data-name="Path 8437"
          d="M336.969,392.08a3.055,3.055,0,0,0-3.051,3.051V426.21a3.051,3.051,0,0,0,6.1,0V395.131A3.055,3.055,0,0,0,336.969,392.08Z"
          transform="translate(-178.375 -239.715)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_8438"
          data-name="Path 8438"
          d="M362.622,392.08a3.055,3.055,0,0,0-3.051,3.051V426.21a3.051,3.051,0,0,0,6.1,0V395.131A3.055,3.055,0,0,0,362.622,392.08Z"
          transform="translate(-191.459 -239.715)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_8439"
          data-name="Path 8439"
          d="M388.291,392.08a3.055,3.055,0,0,0-3.051,3.051V426.21a3.051,3.051,0,0,0,6.1,0V395.131A3.055,3.055,0,0,0,388.291,392.08Z"
          transform="translate(-204.55 -239.715)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_8440"
          data-name="Path 8440"
          d="M413.96,429.262a3.055,3.055,0,0,0,3.051-3.051V395.132a3.051,3.051,0,0,0-6.1,0v31.079A3.055,3.055,0,0,0,413.96,429.262Z"
          transform="translate(-217.642 -239.715)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_8441"
          data-name="Path 8441"
          d="M175.13,299.186H164.108a3.055,3.055,0,0,0-3.051,3.051v11.014a3.055,3.055,0,0,0,3.051,3.051H175.13a3.055,3.055,0,0,0,3.051-3.051V302.237A3.056,3.056,0,0,0,175.13,299.186ZM172.079,310.2h-4.919v-4.912h4.919Z"
          transform="translate(-90.214 -192.338)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_8442"
          data-name="Path 8442"
          d="M220.855,299.186H209.84a3.055,3.055,0,0,0-3.051,3.051v11.014a3.055,3.055,0,0,0,3.051,3.051h11.014a3.055,3.055,0,0,0,3.051-3.051V302.237A3.055,3.055,0,0,0,220.855,299.186ZM217.8,310.2h-4.912v-4.912H217.8Z"
          transform="translate(-113.538 -192.338)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_8443"
          data-name="Path 8443"
          d="M252.507,302.238v11.014a3.055,3.055,0,0,0,3.051,3.051H266.58a3.055,3.055,0,0,0,3.051-3.051V302.238a3.055,3.055,0,0,0-3.051-3.051H255.558A3.055,3.055,0,0,0,252.507,302.238Zm6.1,3.051h4.92V310.2h-4.92Z"
          transform="translate(-136.855 -192.338)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_8444"
          data-name="Path 8444"
          d="M248.153,278.306h-6.132V151.141h1.868a3.055,3.055,0,0,0,3.051-3.051V132.543a3.055,3.055,0,0,0-3.051-3.051h-5.436a56.6,56.6,0,0,0-112,0H121a3.055,3.055,0,0,0-3.051,3.051v15.547A3.055,3.055,0,0,0,121,151.141h1.86v17.67L109.423,174.2v-6.489a3.053,3.053,0,0,0-1.34-2.523,3,3,0,0,0-2.843-.305L77.8,175.872a3.046,3.046,0,0,0-1.92,2.828v26.9H70.25L65.368,112.29a3.041,3.041,0,0,0,1.775-2.75V94.112a3.055,3.055,0,0,0-3.051-3.051H34.777a3.055,3.055,0,0,0-3.051,3.051v15.428a3.034,3.034,0,0,0,1.711,2.707L28.544,205.6h-.368a3.055,3.055,0,0,0-3.051,3.051v69.652h-5.6a3.051,3.051,0,1,0,0,6.1H248.154a3.051,3.051,0,1,0,0-6.1ZM37.828,97.162H61.041v9.325H37.828Zm1.694,15.427H59.28L64.147,205.6h-29.5ZM75.88,215.516v62.79H31.227v-66.6H75.88ZM182.454,87.227a50.347,50.347,0,0,1,49.836,42.265H132.614A50.309,50.309,0,0,1,182.454,87.227ZM128.006,278.306H81.983V218.568h46.023Zm30.178-55.02v55.02H134.109V218.568h24.076Zm0-48.047v37.226h-76.2v-31.7l21.337-8.551V178.7a3.053,3.053,0,0,0,1.34,2.523,3,3,0,0,0,2.843.305l19.6-7.854.005,0,3.648-1.463V178.7a3.053,3.053,0,0,0,1.34,2.523,3,3,0,0,0,2.843.305l23.25-9.318Zm65.388,103.067H164.287V226.337h59.285v51.969Zm-51.225-73.328a3.055,3.055,0,0,0,3.051-3.051v-4.443h2.009a4.962,4.962,0,0,1,5.12,4.785v7.45h-3.349a3.055,3.055,0,0,0-3.051,3.051v7.465H164.287V204.977h8.06Zm-8.06-6.1V178.29H169.3v20.585h-5.009Zm32.649,10.843h-8.305v-7.45a11.078,11.078,0,0,0-11.223-10.888H175.4v-8.045h4.3c9.5,0,17.236,7.368,17.236,16.425v9.958Zm3.051,6.1h1.861v4.413H182.23v-4.413h17.757Zm35.931,62.485h-6.244v-55.02a3.055,3.055,0,0,0-3.051-3.051H207.951V212.77a3.055,3.055,0,0,0-3.051-3.051h-1.86v-9.958c0-12.421-10.471-22.528-23.339-22.528h-4.3v-1.995a3.055,3.055,0,0,0-3.051-3.051h-8.06v-4.48a3.053,3.053,0,0,0-1.34-2.523,3,3,0,0,0-2.843-.305L136.856,174.2v-6.489a3.053,3.053,0,0,0-1.34-2.523,3,3,0,0,0-2.843-.305l-3.706,1.485V151.141H235.919V278.306Zm4.919-133.268H124.054v-9.444H240.838Z"
          transform="translate(-16.477 -81.124)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);
