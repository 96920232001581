import emailjs from 'emailjs-com';
import { messageSuccess, messageError } from './messages';
import i18n from '../i18n';

export const sendFromContactForm = async (
  name,
  companyName,
  email,
  message
) => {
  const templateMail = {
    fromName: name,
    companyName: companyName,
    replyTo: email.toLowerCase(),
    message: message
  };
  const send = await sendMail(templateMail, 'template_6lzwfoj');
  return send;
};

export const sendMail = async (templateParams, template) => {
  let isSuccess;
  await emailjs
    .send(
      'service_wn3mc2g',
      template,
      templateParams,
      'user_AN2m1ly0PlwXVYystD6sr'
    )
    .then(
      () => {
        messageSuccess(i18n.t('emailSucces'));
        isSuccess = true;
      },
      () => {
        messageError(i18n.t('emailError'));
        isSuccess = false;
      }
    );
  return isSuccess;
};
