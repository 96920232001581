import Footer from '../../components/footer';
import AboutUs from '../../components/home/aboutUs';
import Team from '../../components/home/team';
import Banner from '../../components/home/banner';
import ContactUs from '../../components/home/contactUs';
import Projects from '../../components/home/projects';
import Sustainability from '../../components/home/sustainability';
import MainMenu from '../../components/menu';

const Home = () => {
  return (
    <>
      <MainMenu />
      <Banner />
      <AboutUs />
      <Team />
      <Projects />
      <Sustainability />
      <ContactUs />
      <Footer />
    </>
  );
};

export default Home;
