import styled from 'styled-components';
import { H1, Text } from '../../../components/typography';
import { useTranslation } from 'react-i18next';
import { assetUrl } from '../../../utils/assets.js';

const Container = styled.div`
  height: 100vh;
  position: relative;
`;
const Title = styled(H1)`
  margin: 0px;
  line-height: 1em;
  color: ${({ theme }) => theme.$white};
`;
const Description = styled(Text)`
  color: ${({ theme }) => theme.$white};
  width: 60%;
  text-shadow: 1px 1px 10px ${({ theme }) => theme.$black};
  margin-bottom: 4rem;
`;
const Image = styled.div`
  position: absolute;
  background: url(${({ src }) => src});
  background-position: bottom center;
  background-size: cover;
  filter: brightness(0.7);
  z-index: -1;
  inset: 0;
`;
const Link = styled.a`
  display: inline-block;
  text-shadow: 1px 1px 10px ${({ theme }) => theme.$black};
  border: 3px solid ${({ theme }) => theme.$white};
  color: ${({ theme }) => theme.$white};
  padding: 0.75rem 1.5rem;
  font-size: 2rem;
  border-radius: ${({ theme }) => theme.$roundness};
`;

const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  padding: ${({ theme }) => theme.$mobileSpacing};
  right: 0;
  bottom: 0;
  left: 0;
  color: ${({ theme }) => theme.$white};
`;

export const Header = ({ name, url, hero_description, cover }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Image src={cover && assetUrl(cover)} />
      <Content>
        <Title>{name}</Title>
        <Description>{hero_description}</Description>
        {url && (
          <Link target="_blank" href={url}>
            {t('project.header.website')}
          </Link>
        )}
      </Content>
    </Container>
  );
};
