export const location = ({ ...props }) => (
  <svg
    {...props}
    id="_52_location_pin_holder"
    data-name="52  location, pin, holder"
    xmlns="http://www.w3.org/2000/svg"
    width="64.592"
    height="82.208"
    viewBox="0 0 64.592 82.208"
  >
    <path
      id="Path_8400"
      data-name="Path 8400"
      d="M37.3,84.208a2.936,2.936,0,0,1-1.967-.763C34.1,82.329,5,55.9,5,34.3a32.3,32.3,0,0,1,64.592,0c0,21.609-29.1,48.033-30.329,49.149a2.936,2.936,0,0,1-1.967.763Zm0-76.336A26.424,26.424,0,0,0,10.872,34.3C10.872,50.326,30.866,71,37.3,77.22,43.726,71,63.72,50.326,63.72,34.3A26.424,26.424,0,0,0,37.3,7.872Zm0,44.04A17.616,17.616,0,1,1,54.912,34.3,17.616,17.616,0,0,1,37.3,51.912Zm0-29.36A11.744,11.744,0,1,0,49.04,34.3,11.744,11.744,0,0,0,37.3,22.552Z"
      transform="translate(-5 -2)"
      fill="currentColor"
    />
  </svg>
);
