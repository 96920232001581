import Color from 'color';

const prefersDark = () =>
  window.matchMedia('(prefers-color-scheme: dark)').matches;

const hasInvertedTheme = () =>
  document.documentElement.classList.contains('invert');

const isDark = () => !(prefersDark() ^ hasInvertedTheme());

const baseTheme = {
  $primary: '#B1C91E',
  $warning: '',
  $danger: '',
  $mono: '',
  $gray: '#58585B',
  $black: '#1d1d1d',
  $white: '#fff',
  $spacingBig: '',
  $spacing: '10rem',
  $mobileSpacing: '3rem',
  $roundness: '3rem',
  $roundnessSmall: '1rem',
  $phone: '414px',
  $tablet: '768px',
  $iconFaceColor: 'var(--icon-color)',
  $foreground: 'var(--foreground-color)',
  $background: 'var(--background-color)',
  $fontBold: 'HurmeGeometricSans-bold, sans-serif'
};

const expandTheme = (t) => {
  t['$primary05'] = Color(t['$primary']).lighten(0.95).toString();
  t['$mono90'] = Color(t['$primary']).darken(0.4).toString();
  t['$mono10'] = Color(t['$primary']).lighten(0.4).toString();
  t['$gray10'] = Color(t['$gray']).lightness(90).toString();
  t['$shadow'] = `5px 5px ${t['$gray10']}`;
  t.$isDark = isDark;

  return t;
};

export default expandTheme(baseTheme);
