import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import propertyImg from '../../../build/img/aboutus/property_pro.svg';
import architectureImg from '../../../build/img/aboutus/ga_logoNegro.webp';
import ModalVideo from 'react-modal-video';

import { assetUrl } from '../../../utils/assets';

const AboutUs = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(1);
  const [isVideoOpen, setVideoOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [changeText, setChangeText] = useState(false);

  const listenedResize = () => {
    const widthWindow = window.innerWidth;

    if (widthWindow > 780) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    if (widthWindow > 1012) {
      setChangeText(false);
    } else {
      setChangeText(true);
    }
  };

  useEffect(() => {
    listenedResize();
    window.addEventListener('resize', listenedResize);
  }, []);

  return (
    <section className="section-about-us" id="aboutUs">
      {!isMobile && (
        <>
          {tab === 1 && (
            <div className="image-aboutUs background-building">
              <video
                width="100%"
                height="100%"
                autoPlay
                muted
                loop
                onClick={() => setVideoOpen(true)}
              >
                <source src={assetUrl('video/trailer.m4v')} />
              </video>
              <ModalVideo
                channel="custom"
                url={assetUrl('video/garnier.m4v')}
                autoplay
                isOpen={isVideoOpen}
                onClose={() => setVideoOpen(false)}
              />
            </div>
          )}
          {tab === 2 && (
            <div className="image-aboutUs background-office">
              <a>.</a>
            </div>
          )}
          {tab === 3 && (
            <div className="image-aboutUs background-parking">
              <a>.</a>
            </div>
          )}
          {tab === 4 && (
            <div className="image-aboutUs background-tennisCourt">
              <a>.</a>
            </div>
          )}
        </>
      )}
      <div>
        <div className={`aboutsUs-title ${isMobile && 'gap-bottom'}`}>
          <h2>{t('aboutUs.title')}</h2>
        </div>
        <div className="about-us-text">
          {isMobile && (
            <>
              {tab === 1 && (
                <div className="image-aboutUs background-building">
                  <video
                    width="100%"
                    height="100%"
                    autoPlay
                    muted
                    loop
                    onClick={() => setVideoOpen(true)}
                  >
                    <source src={assetUrl('video/trailer.m4v')} />
                  </video>
                  <ModalVideo
                    channel="custom"
                    url={assetUrl('video/garnier.m4v')}
                    autoplay
                    isOpen={isVideoOpen}
                    onClose={() => setVideoOpen(false)}
                  />
                </div>
              )}
              {tab === 2 && (
                <div className="image-aboutUs background-office">
                  <a>.</a>
                </div>
              )}
              {tab === 3 && (
                <div className="image-aboutUs background-parking">
                  <a>.</a>
                </div>
              )}
              {tab === 4 && (
                <div className="image-aboutUs background-tennisCourt">
                  <a>.</a>
                </div>
              )}
            </>
          )}
          <Tabs>
            <TabList>
              <Tab onClick={() => setTab(1)}>
                {changeText
                  ? t('aboutUs.tabCorporateProfile.titleMobile')
                  : t('aboutUs.tabCorporateProfile.title')}
              </Tab>
              <Tab onClick={() => setTab(2)}>
                {t('aboutUs.tabValues.title')}
              </Tab>
              <Tab onClick={() => setTab(3)}>
                {t('aboutUs.tabServices.title')}
              </Tab>
              <Tab onClick={() => setTab(4)}>
                {changeText
                  ? t('aboutUs.tabRelatedCompanies.titleMobile')
                  : t('aboutUs.tabRelatedCompanies.title')}
              </Tab>
            </TabList>

            <TabPanel>
              <p className="gap-top gap-bottom">
                {t('aboutUs.tabCorporateProfile.firstParagraph')}
              </p>
              <p>{t('aboutUs.tabCorporateProfile.secondParagraph')}</p>
            </TabPanel>
            <TabPanel>
              <ul>
                <li className="gap-bottom gap-top aboutUs">
                  <div className="img-about background-hands">
                    <a>.</a>
                  </div>
                  <div className="text-content">
                    <p className="bold p-dot-inside">
                      {t('aboutUs.tabValues.responsibility.title')}
                    </p>
                    <p>{t('aboutUs.tabValues.responsibility.paragraph')}</p>
                  </div>
                </li>
                <li className="gap-bottom aboutUs">
                  <div className="img-about background-atomicHandshake">
                    <a>.</a>
                  </div>
                  <div className="text-content">
                    <p className="bold p-dot-inside">
                      {t('aboutUs.tabValues.integrity.title')}
                    </p>
                    <p>{t('aboutUs.tabValues.integrity.paragraph')}</p>
                  </div>
                </li>
                <li className="gap-bottom aboutUs">
                  <div className="img-about background-handshake">
                    <a>.</a>
                  </div>
                  <div className="text-content">
                    <p className="bold p-dot-inside">
                      {t('aboutUs.tabValues.commitment.title')}
                    </p>
                    <p>{t('aboutUs.tabValues.commitment.paragraph')}</p>
                  </div>
                </li>
              </ul>
            </TabPanel>
            <TabPanel>
              <p className="gap-top gap-bottom">
                {t('aboutUs.tabServices.firstParagraph')}
              </p>
              
              <ul>
                <li>
                  <p className="p-dot-inside">
                    {t('aboutUs.tabServices.listParagraph.item1')}
                  </p>
                </li>
                <li>
                  <p className="p-dot-inside">
                    {t('aboutUs.tabServices.listParagraph.item2')}
                  </p>
                </li>
                <li>
                  <p className="p-dot-inside">
                    {t('aboutUs.tabServices.listParagraph.item3')}
                  </p>
                </li>
                <li>
                  <p className="p-dot-inside">
                    {t('aboutUs.tabServices.listParagraph.item4')}
                  </p>
                </li>
              </ul>

              <p className="bold gap-top gap-bottom">
                {t('aboutUs.tabServices.secondParagraph')}
              </p>

              <ul>
                <li>
                  <p className="p-dot-inside">
                    {t('aboutUs.tabServices.list.item1')}
                  </p>
                </li>
                <li>
                  <p className="p-dot-inside">
                    {t('aboutUs.tabServices.list.item2')}
                  </p>
                </li>
                <li>
                  <p className="p-dot-inside">
                    {t('aboutUs.tabServices.list.item3')}
                  </p>
                </li>
              </ul>
              
              <p className="gap-top gap-bottom">
                {t('aboutUs.tabServices.thirdParagraph')}
              </p>
            </TabPanel>
            <TabPanel>
              <p className="gap-top gap-bottom">
                {t('aboutUs.tabRelatedCompanies.mainText')}
              </p>
              <img
                className="img-background-companies gap-bottom theme-invert"
                src={architectureImg}
              />
              <p className="bold">
                {t('aboutUs.tabRelatedCompanies.cmpArchitecture.type')}
              </p>
              <p>{t('aboutUs.tabRelatedCompanies.cmpArchitecture.name')}</p>
              <p className="gap-bottom">
                <a
                  href={
                    'https://' +
                    t('aboutUs.tabRelatedCompanies.cmpArchitecture.webSite')
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('aboutUs.tabRelatedCompanies.cmpArchitecture.webSite')}
                </a>
              </p>
              <img
                className="gap-bottom theme-invert"
                style={{ height: '120px' }}
                src={propertyImg}
              />
              <p className="bold">
                {t('aboutUs.tabRelatedCompanies.cmpProperty.type')}
              </p>
              <p>{t('aboutUs.tabRelatedCompanies.cmpProperty.name')}</p>
              <p>
                <a
                  href={
                    'https://' +
                    t('aboutUs.tabRelatedCompanies.cmpProperty.webSite')
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('aboutUs.tabRelatedCompanies.cmpProperty.webSite')}
                </a>
              </p>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
