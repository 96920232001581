import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendFromContactForm } from '../../../utils/sendMail';
import { isValidate, isValidMail } from '../../../utils/helpers';

const ContactUs = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState();
  const [message, setMessage] = useState();
  const [validName, setValidName] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validCompanyName, setValidCompanyName] = useState(false);
  const [validMessage, setValidMessage] = useState(false);

  const resetValid = () => {
    setValidName(false);
    setValidEmail(false);
    setValidCompanyName(false);
    setValidMessage(false);
  };

  const resetInput = () => {
    setName('');
    setEmail('');
    setCompanyName('');
    setMessage('');
  };

  const validForm = () => {
    let send = true;

    if (isValidate(name)) {
      setValidName(true);
      setLoading(false);
      send = false;
    }

    if (isValidMail(email)) {
      setValidEmail(true);
      setLoading(false);
      send = false;
    }

    if (isValidate(companyName)) {
      setValidCompanyName(true);
      setLoading(false);
      send = false;
    }

    if (isValidate(message)) {
      setValidMessage(true);
      setLoading(false);
      send = false;
    }

    return send;
  };

  const handleSummit = async () => {
    resetValid();
    setLoading(true);
    if (validForm()) {
      const sendMail = await sendFromContactForm(name, companyName, email, message);
      setLoading(false);

      if (sendMail) {
        resetInput();
      }
    }
  };

  return (
    <section id="contactUs" className="contactUs-img">
      <div className="container wrap-form-contact">
        <div className="form-contact">
          <h1>{t('contactUs.title')}</h1>
          <input
            type="text"
            placeholder={t('contactUs.name')}
            maxLength="100"
            onChange={(e) => setName(e.target.value)}
            value={name}
            className={validName ? 'required' : ''}
            required
          />
          <small>{validName && t('contactUs.needName')}</small>
          <input
            type="email"
            placeholder={t('contactUs.email')}
            maxLength="100"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className={validEmail ? 'required' : ''}
            required
          />
          <small>{validEmail && t('contactUs.needEmail')}</small>
          <input
            type="text"
            placeholder={t('contactUs.companyName')}
            maxLength="100"
            onChange={(e) => setCompanyName(e.target.value)}
            value={companyName}
            className={validCompanyName ? 'required' : ''}
            required
          />
          <small>{validCompanyName && t('contactUs.needCompanyName')}</small>
          <textarea
            placeholder={t('contactUs.message')}
            maxLength="250"
            onChange={(e) => setMessage(e.target.value)}
            className={validMessage ? 'required' : ''}
            required
            value={message}
          />
          <small>{validMessage && t('contactUs.needMessage')}</small>
          <button
            onClick={() => {
              setLoading(true);
              handleSummit();
            }}
          >{loading ? <div className="loader"></div> : t('contactUs.send')}</button>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
