import React, { useState, useEffect, useRef } from 'react';
import Flickity from 'react-flickity-component';
import Markdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { assetUrl } from '../../../utils/assets';
import { teamData } from '../../../data/team';

const Team = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [, setChangeText] = useState(false);
  const [selectedMember, setSelectedMember] = useState(false);
  const [{ team, members }, setData] = useState(() => teamData(i18n.language));
  const flkty = useRef();
  const teamImage = useRef();

  const listenedResize = () => {
    const widthWindow = window.innerWidth;

    if (widthWindow > 780) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    if (widthWindow > 1012) {
      setChangeText(false);
    } else {
      setChangeText(true);
    }
  };

  const handleOnClick = (e, idx) => {
    e.preventDefault();
    if (flkty.current.selectedIndex === idx + 1) {
      setSelectedMember(0);
      flkty.current.select(0);
    } else {
      setSelectedMember(idx + 1);
      flkty.current.select(idx + 1);
    }
  };

  useEffect(() => {
    setData(teamData(i18n.language));
  }, [i18n.language]);

  useEffect(() => {
    listenedResize();
    window.addEventListener('resize', listenedResize);
  }, []);

  useEffect(() => {
    const onScroll = () => {
      const top = flkty.current.element.offsetTop + teamImage.current.offsetTop;
      const height = teamImage.current.offsetHeight;
      const currentTop = document.documentElement.scrollTop;
      const section = flkty.current.element.parentElement.offsetTop;
      const offset = top - section;
      const pos = currentTop - top;

      const max = height * 0.124; // Depends on photo

      if (pos > 0) {
        teamImage.current.style.backgroundPosition = `center ${
          currentTop - top
        }px`;
      } else {
        teamImage.current.style.backgroundPosition = `center ${
          ((offset - Math.max(0, currentTop - section)) / offset) * -max
        }px`;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <section className="section-team" id="team">
      <div className={`team-title ${isMobile ? 'gap-bottom' : ''}`}>
        <h2>{t('team.title')}</h2>
      </div>

      <div className="members">
        {members.map(({ name, picture }, idx) => (
          <a key={idx} href="#" onClick={(e) => handleOnClick(e, idx)}>
            <img
              src={assetUrl(picture)}
              className={selectedMember === idx + 1 ? 'selected' : ''}
            />
            {name}
          </a>
        ))}
      </div>

      <Flickity
        className="team-carrousel"
        flickityRef={(c) => (flkty.current = c)}
        options={{
          adaptiveHeight: true,
          prevNextButtons: false,
          pageDots: false
        }}
        static
      >
        <div
          className="team-full"
          ref={teamImage}
          style={{ backgroundImage: `url(${assetUrl(team.image)})` }}
        ></div>
        {members.map(({ name, title, picture, description }, idx) => (
          <div className="team-card" key={idx}>
            <div className="member-picture">
              <img src={assetUrl(picture)} />
              <div className="name">{name}</div>
              <div className="title">{title}</div>
            </div>
            <Markdown className="member-description">{description}</Markdown>
          </div>
        ))}
      </Flickity>
    </section>
  );
};

export default Team;
