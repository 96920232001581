import {
  HashRouter as Router,
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import 'normalize.css';
import './app.scss';
import ReactGA from 'react-ga';

// Styled components baseTheme
//
import theme from './theme';
import { ThemeProvider } from 'styled-components';
// Pages
import Home from './pages/Home';
import Projects from './pages/Projects';
import Project from './pages/Project';
import { useEffect } from 'react';

const ScrollToTop = ({ children }) => {
  const pathname = useLocation();

  useEffect(() => {
    const target = document.getElementById(
      window?.location?.hash?.split('#')?.splice(-1, 1)?.[0]
    );
    if (target) {
      target.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return <>{children} </>;
};

const App = () => {
  ReactGA.pageview(window.location.pathname);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/project/:slug" component={Project} />
            <Route exact path="/portfolio" component={Projects} />
            <Route path="/" component={Home} />
          </Switch>
        </ScrollToTop>
      </Router>
    </ThemeProvider>
  );
};

export default App;
