export const en = {
  theme: 'Theme',
  dark: 'Dark',
  light: 'Light',
  spanish: 'Spanish',
  english: 'English',
  close: 'Close',
  sure: 'Sure',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  emailSucces: 'The mail has been sent successfully',
  emailError: 'The mail could not be sent',
  download: 'Download',
  menu: {
    aboutUs: 'About Us',
    team: 'Team',
    projects: 'Projects',
    sustainability: 'Sustainability',
    contactUs: 'Contact Us'
  },
  team: {
    title: 'Team'
  },
  footer: {
    address:
      'D: 3rd Floor of the Lindora Corporate Center, Santa Ana, Costa Rica.',
    employmentBoard: 'Employment board'
  },
  banner: {
    title: 'GARNIER',
    subTitle: 'Enhancing Land',
    paragraph: `Garnier & Garnier Desarrollos Inmobiliarios is a 
    Costa Rican group with more than 28 years of experience in real estate development.`
  },
  aboutUs: {
    title: 'About Us',
    paragraph: `Garnier & Garnier Desarrollos Inmobiliarios is a Costa Rican group 
    with more than 28 years of experience in the conceptualization, development, 
    and disposition of Real Estate Projects throughout Costa Rica. Garnier 
    has been involved in the design and construction of over 1,500,000 square meters of development throughout its history,
    with well over $500 MM of construction in the last 5 years.  Focused on the development of industrial parks and free trade zones, 
    corporate office parks, and hospitality/residential projects, Garnier brings a sustainable and innovative approach to traditional real estate development.`,
    tabCorporateProfile: {
      title: 'Corporate Profile',
      titleMobile: 'Profile',
      firstParagraph: `Garnier & Garnier Desarrollos Inmobiliarios is a Costa Rican group 
      with more than 28 years of experience in the conceptualization, development, 
      and disposition of Real Estate Projects throughout Costa Rica. Garnier 
      has been involved in the design and construction of over 1,500,000 square meters of development throughout its history,
      with well over $500 MM of construction in the last 5 years.  Focused on the development of industrial parks and free trade zones, 
      corporate office parks, and hospitality/residential projects, Garnier brings a sustainable and innovative approach to traditional real estate development.`,
      secondParagraph: `
      
      `
    },
    tabValues: {
      title: 'Values',
      responsibility: {
        title: 'Responsibility',
        paragraph: `It is the fundamental pillar that guides all our actions. 
        We tirelessly strive to achieve our goals and take pride in being a reliable and trustworthy 
        company for all those who interact with us. Our concern for the well-being of our customers, 
        the environment, and the communities in which we operate is paramount, as is the 
        care and support for our associates and their families.`
      },
      integrity: {
        title: 'Integrity',
        paragraph: ` It is the compass that directs every step we take. 
        We carry out our work with honesty, transparency, and respect, always in harmony with our strong values and principles. 
        We firmly believe in the importance of acting with coherence and consistency, maintaining high ethical standards in every situation we face.`
      },
      commitment: {
        title: 'Commitment',
        paragraph: `It is the foundation of our pride in belonging to Garnier & Garnier. 
        We unite in a single vision, wholeheartedly embracing the company's objectives and values as our own. 
        We work with determination and passion, surpassing what is agreed upon or expected, because we know that it is dedication and absolute commitment that will propel us towards success and constant progress.`
      }
    },
    tabServices: {
      title: 'Services',
      firstParagraph: 'Garnier & Garnier is a company specialized in the real estate sector, providing comprehensive services for real estate development projects. Our approach is focused on meeting the specific needs of each client through open and effective communication channels. Some key aspects where our company excels are:',
      secondParagraph: 'Garnier & Garnier offer services as:',
      thirdParagraph: 'Our expertise spans from land selection to obtaining permits, financing, and asset management. We aim to establish enduring and trustworthy relationships with our clients.',
      list: {
        item1: 'Real Estate Developer',
        item2: 'Construction manager',
        item3: 'Asset manager'
      },
      listParagraph: {
        item1: 'Response time: We prioritize promptness in attending to client requests and inquiries, ensuring efficient and high-quality service.',
        item2: 'Follow-up: Maintaining close monitoring of projects and development stages is essential to ensure everything progresses according to the clients plans and expectations.',
        item3: 'Technical and professional advice: Garnier & Garnier stands out for having a team of specialized professionals in the real estate sector, capable of providing expert guidance on various aspects of project development.',
        item4: 'Experience in various roles: Our company has worked as a real estate developer, construction manager, and asset manager, showcasing our versatility and ability to handle different stages of the real estate process.'
      }
    },
    tabRelatedCompanies: {
      title: 'Related Companies',
      titleMobile: 'Companies',
      mainText: 'Several companies complement our services:',
      cmpArchitecture: {
        type: 'Architecture',
        name: 'Garnier Arquitectos',
        webSite: 'www.garnierarquitectos.com'
      },
      cmpProperty: {
        type: 'Property Management',
        name: 'Property Pro',
        webSite: ''
      }
    }
  },
  contactUs: {
    title: 'Contact Us',
    send: 'Send',
    name: 'Name',
    email: 'Email',
    companyName: 'Company Name',
    message: 'Message',
    needName: 'Name is required',
    needEmail: 'Email is required',
    needCompanyName: 'Company name is required',
    needMessage: 'Message is required'
  },
  projects: {
    title: 'Recent Projects',
    titleShort: 'Projects',
    swipeMsg: 'Swipe to watch our most recent projects.',
    portfolio: 'More Projects'
  },
  sustainability: {
    title: 'Sustainability',
    imageTitle: 'Strategy',
    firstParagraph: `Garnier set out five pilars of corporate foundations for its operation
    and sustainability strategy. The company worked with experts 
    in diferent fields to devise a MATRIX of standards or requirements per 
    foundation, which encourages analysis from the planning stage of 
    each real estate development. The matrix becomes a guide to 
    formally and practically establish the vision of sustainability in the 
    company.`,
    secondParagraph: `Each of the foundations are broken down into a series of 
    performance indicators and a minimum total must be met in order 
    to carry out the project. The sustainability committee monitors each 
    project to verify the way in which the matrix is implemented, using a 
    scoring system.`,
    thirdParagraph: `The degree of involvement of the team in the strategy is the basis
    for building and making a comprehensive vision of sustainability a 
    reality; therefore, this strategy is accompanied by a culture plan so 
    that in each decision in the development process, everyone in the 
    company has the awareness and basic knowledge to priorize 
    comprehensive sustainability.`
  },
  reports: {
    imageTitle: 'Reports',
    download: 'Download Reports',
    firstParagraph:
      'The content of G&G’s annual sustainability report is a means to be accountable to stakeholders and the general public, honoring the principles of transparency, clarity and trust that they deserve. We want to share our practices and learnings with as many industry players as possible, with the conviction that together we can have an impact as an industry on the country we want to build.'
  },
  portfolio: {
    title: 'Projects'
  },
  project: {
    header: {
      website: 'Website'
    },
    type: {
      residential: 'Residential',
      commercial: 'Commercial',
      free_zone_industrial: 'Free Zone & Industrial',
      hotel_resort: 'Hotel & Resort',
      hospitalary: 'Hospitality',
      empty: ''
    },
    detail: {
      area: 'Area',
      opening: 'Opening',
      location: 'Location'
    }
  }
};
