export const area = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="85.555"
    height="85.555"
    viewBox="0 0 85.555 85.555"
  >
    <path
      id="Path_8404"
      data-name="Path 8404"
      d="M95.027,19h-68.5L19,26.528v68.5l7.528,7.528h68.5l7.528-7.528v-68.5ZM21.7,68.074,39.773,50H53.481L21.7,81.782Zm0-3.811V50H35.962Zm0,21.331L57.293,50H65.61l2.7,2.7L24.391,96.607l-2.7-2.7Zm4.6,12.919L70.211,54.6l1.348,1.348V65.61L37.309,99.86H27.644Zm45.263-16.73L53.481,99.86H41.121L71.559,69.422ZM57.293,99.86,71.559,85.594V99.86ZM99.86,93.911,93.911,99.86H74.254V54.829L66.726,47.3H21.7V27.644L27.644,21.7H93.911l5.949,5.949Z"
      transform="translate(-18 -18)"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);
