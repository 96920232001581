import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { assetUrl } from '../../../utils/assets';

const Sustainability = () => {
  const { t } = useTranslation();
  const [showReports, setShowReports] = useState(false);
  const [showSustainability, setShowSustainability] = useState(false);

  const toggleReports = () => setShowReports((s) => !s);
  const toggleSustainability = () => setShowSustainability((s) => !s);

  return (
    <section className="section-sustainability" id="sustainability">
      <div className="sustainability-title">
        <h2>{t('sustainability.title')}</h2>
      </div>
      <div
        className={`sustainability-grid ${
          (showSustainability || showReports) && 'gap'
        }
        `}
      >
        <>
          {(showSustainability || (!showSustainability && !showReports)) && (
            <div
              className="img-sustainability background-sustainability"
              onClick={toggleSustainability}
            >
              <h2 className="imageTitle">{t('sustainability.imageTitle')}</h2>
            </div>
          )}
        </>
        <>
          {(showReports || (!showSustainability && !showReports)) && (
            <div
              className="img-sustainability background-reports"
              onClick={toggleReports}
            >
              <h2 className="imageTitle">{t('reports.imageTitle')}</h2>
            </div>
          )}
        </>

        {showSustainability && (
          <div className="sustainability-text">
            <div className="text-area">
              <p className="gap-top gap-bottom">
                {t('sustainability.firstParagraph')}
              </p>
              <p className="gap-bottom">
                {t('sustainability.secondParagraph')}
              </p>
              <p>{t('sustainability.thirdParagraph')}</p>
            </div>
          </div>
        )}

        {showReports && (
          <div className="sustainability-text">
            <div className="text-area">
              <p className="gap-top gap-bottom">
                {t('reports.firstParagraph')}
              </p>
              <p className="gap-top gap-bottom">
                <select
                  defaultValue=""
                  onChange={({ target }) => {
                    const { value } = target;

                    window.open(value);
                  }}
                >
                  <option selected value="" disabled>
                    {t('reports.download')}
                  </option>
                  <option value={assetUrl('reports/Corporativo.pdf')}>
                    Código de Conducta Ética Corporativo
                  </option>
                  <option value={assetUrl('reports/reporte-2023.pdf')}>
                    Reporte 2023
                  </option>
                  <option value={assetUrl('reports/reporte-2022.pdf')}>
                    Reporte 2022
                  </option>
                  <option value={assetUrl('reports/reporte-2021.pdf')}>
                    Reporte 2021
                  </option>
                  <option value={assetUrl('reports/reporte-2020.pdf')}>
                    Reporte 2020
                  </option>
                  <option value={assetUrl('reports/reporte-2019.pdf')}>
                    Reporte 2019
                  </option>
                  <option value={assetUrl('reports/reporte-2018.pdf')}>
                    Reporte 2018
                  </option>
                  <option value={assetUrl('reports/reporte-2017.pdf')}>
                    Reporte 2017
                  </option>
                </select>
              </p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Sustainability;
