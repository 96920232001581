import { useTranslation } from 'react-i18next';
import { assetUrl } from '../../../utils/assets';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className="hero-img">
      <div className="container hero-shot">
        <div className="hero-content">
          <img src={assetUrl('garnier_typo.svg')} />
          <p>{t('banner.paragraph')}</p>
        </div>
      </div>
    </section>
  );
};

export default Banner;
