import styled, { useTheme } from 'styled-components';

import { toggle } from './toggle';
export const StyledDarkModeSwitch = styled(toggle)``;

export const DarkModeSwitch = ({ onClick, ...props }) => {
  const theme = useTheme();

  return (
    <div onClick={onClick} style={{ transform: 'translateY(5px)' }}>
      <StyledDarkModeSwitch dark={theme.$isDark()} {...props} />
    </div>
  );
};
