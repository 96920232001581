import Footer from '../../components/footer';
import MainMenu from '../../components/menu';
import { useState, useEffect } from 'react';
import { Header } from './Header';
import { Body } from './Body';
import projects from '../../data/projects.js';
import { useTranslation } from 'react-i18next';

const Project = ({ match }) => {
  const [currentProject, setCurrentProject] = useState({});
  const { i18n } = useTranslation();

  useEffect(() => {
    const project = projects.find(({ slug }) => slug === match?.params?.slug);

    setCurrentProject(Object.assign({}, project, project[i18n.language] || {}));
  }, [match]);

  return (
    <>
      <MainMenu fixed />
      <Header {...currentProject} />
      <Body {...currentProject} />
      <Footer />
    </>
  );
};

export default Project;
