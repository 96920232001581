export const projects = [
  {
    name: 'CCL',
    slug: 'ccl',
    url: 'https://cclindora.com/',
    classification: ['commercial'],
    location: 'Santa Ana',
    area: 'Más de 7000m²',
    opening: '2015',
    cover: '/projects/ccl/cover.webp',
    images: [
      '/projects/ccl/01.webp',
      '/projects/ccl/02.webp',
      '/projects/ccl/03.webp',
      '/projects/ccl/04.webp'
    ],
    grid: {
      order: 1,
      span: [3, 3],
      image: '/projects/ccl/grid.webp'
    },
    hero_description:
      'El Centro Corporativo Lindora está ubicado en la zona de mayor crecimiento empresarial del país, ofrece una infraestructura versátil en un terreno de más de 7000 m2.',
    description:
      'Hay 49 oficinas/ locales comerciales cerca de prestigiosas empresas multinacionales y una amplia oferta de servicios (bancos, restaurantes, supermercados y lugares de conveniencia). Cuenta con una serie de características constructivas que buscan la eficiencia del espacio y la armonía con el entorno.',
    full_description:
      'CCL es un proyecto de vanguardia que plasma la armonía que necesita el usuario en su espacio de trabajo y su conexión con el entorno.\n\nCentro Corporativo Lindora tiene un área total de 5200 m2 de terreno que está dividido en 49 oficinas/locales con áreas desde los 75 m2. Cuenta con la última tecnología en sistemas de seguridad.',
    en: {
      area: 'More than 7000m²',
      hero_description:
        'The Lindora Corporate Center is located in the fastest growing business area of the country, offering a versatile infrastructure in a land of more than 7000 m2.',
      description:
        'There are 49 offices/commercial spaces close to prestigious multinational companies and a wide range of services (banks, restaurants, supermarkets and convenience stores). It has a series of construction features that seek space efficiency and harmony with the environment.',
      full_description:
        'CCL is an avant-garde project that embodies the harmony that the user needs in his workspace and its connection with the environment. Centro Corporativo Lindora has a total area of 5200 m2 of land that is divided into 49 offices/premises with areas starting at 75 m2. It has the latest technology in security systems.'
    }
  },
  {
    name: 'Zona Franca La Lima',
    slug: 'zona-franca-la-lima',
    url: 'https://es.lalimafreezone.com/',
    classification: ['free_zone_industrial'],
    location: 'La Lima, Cartago',
    area: '400.000 m²',
    opening: '2014',
    cover: '/projects/zona-franca-la-lima/cover.webp',
    images: [
      '/projects/zona-franca-la-lima/01.webp',
      '/projects/zona-franca-la-lima/02.webp',
      '/projects/zona-franca-la-lima/03.webp',
      '/projects/zona-franca-la-lima/04.webp'
    ],
    front: {
      order: 2,
      visible: true,
      image: '/projects/zona-franca-la-lima/front.webp'
    },
    grid: {
      order: 2,
      span: [5, 5],
      image: '/projects/zona-franca-la-lima/grid.webp'
    },
    hero_description:
      'Zona Franca y Parque Industrial La Lima es un proyecto estratégicamente ubicado en La Lima, Cartago.',
    description:
      'Abarca 79 hectáreas de uso mixto-comercial, con un perfil tecnológico para la manufactura avanzada, las ciencias de la vida, los centros de logística y el establecimiento tanto de empresas suplidoras como de compañías de servicios, entre otros.',
    full_description:
      'Esta iniciativa busca posicionar la zona como polo de desarrollo, a través de su infraestructura y arquitectura de punta. Este parque industrial es un espacio dinámico de negocios, que pretende la instalación de empresas reconocidas mundialmente; la calidad, el diseño y la eficiencia se articulan para impactar y satisfacer los altos estándares de estas compañías. Clientes Principales: Zollner Electronics, Edwards LifeSciences, Heraeus y Align Technology.',
    en: {
      hero_description:
        'La Lima Free Zone and Industrial Park is a project strategically located in La Lima, Cartago.',
      description:
        'It covers 79 hectares of mixed-commercial use, with a technological profile for advanced manufacturing, life sciences, logistics centers and the establishment of both supply companies and service companies, among others.',
      full_description:
        'This initiative seeks to position the area as a development pole, through its infrastructure and state-of-the-art architecture. This industrial park is a dynamic business space, which seeks the installation of world-renowned companies; quality, design and efficiency are articulated to impact and meet the high standards of these companies. Main clients: Zollner Electronics, Edwards LifeSciences, Heraeus and Align Technology.'
    }
  },
  {
    name: 'El Cafetal',
    slug: 'el-cafetal',
    url: 'https://ccelcafetal.com/es/inicio/',
    classification: ['free_zone_industrial'],
    location: 'Belén, Heredia',
    area: '83.225 m²',
    opening: '2014',
    cover: '/projects/el-cafetal/cover.webp',
    images: [
      '/projects/el-cafetal/01.webp',
      '/projects/el-cafetal/02.webp',
      '/projects/el-cafetal/03.webp',
      '/projects/el-cafetal/04.webp'
    ],
    front: {
      order: 1,
      visible: true,
      image: '/projects/el-cafetal/front.webp'
    },
    grid: {
      order: 3,
      span: [4, 5],
      image: '/projects/el-cafetal/grid.webp'
    },
    hero_description:
      'Complejo empresarial donde la arquitectura del lugar está vinculada al espacio de forma eficiente.',
    description:
      'El Cafetal es un centro corporativo que aprovecha tecnología vanguardista para garantizar la máxima eficiencia de los recursos: logrando tanto el bienestar ambiental como el de sus usuarios. Nuestras siete edificios conforman un espacio accesible, sostenible, cómodo y céntrico.',
    full_description:
      'Diversas compañías multinacionales se encuentran instaladas aquí en la actualidad, las cuales emplean a 5.600 colaboradores en total. Su éxito reafirma las condiciones óptimas de El Cafetal para que empresas de todas las industrias alcancen sus objetivos de manera próspera. Siete edificios verdes de oficinas, amplios jardines, 2100 parqueos e importantes vías de acceso.',
    en: {
      hero_description:
        'El Cafetal, represents a corporate center harnessing state-of-the-art technology to ensure peak resource efficiency, promoting environmental well-being alongside user satisfaction. Comprising seven tower buildings, it offers an accessible, sustainable, comfortable, and centrally located space.',
      description:
        'Numerous multinational companies are presently established here, collectively employing 5,600 personnel. Their presence underscores El Cafetal`s conducive environment for companies across diverse industries to successfully pursue their objectives.',
      full_description:
        'With seven eco-friendly office buildings, extensive gardens, 2,100 parking spaces, and key access roads, it provides an ideal setting for growth and prosperity.'
    }
  },
  {
    name: 'Centro Corporativo La Sabana',
    slug: 'centro-corporativo-sabana',
    classification: ['empty'],
    location: 'Sabana Norte',
    area: '21.706.98 m²',
    opening: 'Próximamente 2023',
    cover: '/projects/centro-corporativo-sabana/01.jpeg',
    images: [
      '/projects/centro-corporativo-sabana/01.jpeg',
      '/projects/centro-corporativo-sabana/02.jpeg',
      '/projects/centro-corporativo-sabana/03.jpeg'
    ],
    grid: {
      order: 4,
      span: [3, 6],
      image: '/projects/centro-corporativo-sabana/grid.jpeg'
    },
    hero_description:
      'Condominio Oficentro la Sabana es un edificio de 18 niveles y 3 sótanos, que abarca hasta 75.000m2 de construcción en donde se albergaran  espacios de oficinas en cada piso asciende a los 2.800m2.',
    description:
      'Su propuesta incluye diversas amenidades, entre ellas, gimnasio, áreas deportivas, spa, miradores, terrazas y balcones con altos estándares de sostenibilidad y Certificación Leed Gold.',
    full_description: '',
    en: {
      hero_description:
        'Oficentro La Sabana it’s a corporate building with 18 th floors and 3 parking  basements with 75,000 meter of construction with office spaces of 2,800 m2 per floor.',
      description:
        'Our proposal includes various amenities, such as a gym, sport áreas, spa, viewpoints, terrace and balconies with a Leed Gold Certification that guarantees hight standards of sustainability.',
      full_description: ''
    }
  },
  {
    name: 'Waldorf Astoria',
    slug: 'Waldorf Astoria',
    classification: ['hospitalary'],
    location: 'Playa Penca, Punta Cacique, Guanacaste, Costa Rica.',
    area: '55.000 m²',
    opening: '2025',
    cover: '/projects/punta-cacique/cover.jpg',
    images: [
      '/projects/punta-cacique/1.jpeg',
      '/projects/punta-cacique/2.jpeg',
      '/projects/punta-cacique/cover.jpg'
    ],
    grid: {
      order: 5,

      span: [6, 4],
      image: '/projects/punta-cacique/cover.jpg'
    },
    hero_description: 'Proyecto hotelero y de residencias.',
    description:
      'El hotel tendrá 188 habitaciones y alrededor de 30 residencias con todas las comodidades y lujo.',
    full_description:
      'Proyecto hotelero Waldorf Astoria es un tipo de hotel que ofrece un servicio inigualable, fuente de momentos y experiencias extraordinarias. Ofrece desde lujosos espacios para ejercicio e increíbles spas, hasta una experiencia culinaria de talla mundial, definidos por elegancia y lujo. El hotel en Playa Penca Guanacaste promete ser una experiencia única por las bellezas naturales y opciones para la aventura que solo Costa Rica tiene, y el confort que tendrán las instalaciones.',
    en: {
      hero_description: 'Waldorf Astoria Hotel Project',
      description:
        'The hotel will have 188 rooms and around 30 residences with all the comforts and luxury.',
      full_description:
        'Waldorf Astoria Hotel Project is a type of hotel that offers unparalleled service, a source of extraordinary moments and experiences. It offers everything from luxurious exercise spaces and incredible spas to a world-class culinary experience, defined by elegance and luxury. The hotel in Playa Penca Guanacaste promises to be a unique experience due to the natural beauty and options for adventure that only Costa Rica has, and the comfort that the facilities will have.'
    }
  },

  {
    name: 'Flexi Park',
    slug: 'flexi-park',
    classification: ['free_zone_industrial'],
    location: 'Calle Portrerillos, San Rafael, Alajuela',
    area: '33.040 m²',
    opening: '2010',
    cover: '/projects/flexi-park/cover.webp',
    images: [
      '/projects/flexi-park/01.webp',
      '/projects/flexi-park/02.webp',
      '/projects/flexi-park/03.webp',
      '/projects/flexi-park/04.webp'
    ],
    grid: {
      order: 6,
      span: [3, 4],
      image: '/projects/flexi-park/grid.webp'
    },
    hero_description:
      'Complejo industrial/comercial de oficinas y bodegas. 51 ofibodegas desde 285 m2 hasta 8.000 m2 y dos torres de tres pisos de oficinas desde 270 m2.',
    description:
      'FLEXIPARK es un moderno complejo de ofibodegas y edificio de oficina, estratégicamente localizado en 6 hectáreas de terreno en la zona comercial de Santa Ana-Lindora.\n\nFlexipark combina la calidad de diseño con la adaptabilidad en la construcción según los requerimientos específicos de cada cliente.',
    full_description:
      'FLEXIPARK es un concepto que fusiona infraestructura de altísimo nivel y la eficiencia que su compañía requiere, desarrollando espacios agradables y amplios.\n\nEn FLEXIPARK usted encontrará:\n\nAmplios accesos para el ingreso a las bodegas.\nVías anchas que facilitan la movilidad de contenedores dentro del parque.\nExtensas zonas de parqueos.\nAmbiente agradable y amplias áreas verdes.\nSistemas avanzados de seguridad y circuito cerrado.\nEl cliente puede optar por espacios para establecer su back office y su bodega en un mismo lugar.\nEl entorno y el ambiente agradables buscan impactar la productividad de las personas.',
    en: {
      hero_description:
        'Industrial/commercial complex of offices and warehouses. 51 ofibodegas from 285 m2 to 8,000 m2 and two three-story office towers from 270 m2.',
      description:
        'FLEXIPARK is a modern office building and warehouse complex, strategically located on 6 hectares of land in the commercial area of Santa Ana-Lindora. Flexipark combines quality design with adaptability in construction according to the specific requirements of each client.',
      full_description:
        'FLEXIPARK is a concept that merges high-level infrastructure and the efficiency that your company requires, developing pleasant and ample spaces. In FLEXIPARK you will find:Wide accesses for the entrance to the warehouses.Wide roads that facilitate the mobility of containers within the park. Wide parking areas. Pleasant environment and large green areas. Advanced security systems and closed circuit. The client can choose spaces to establish its back office and warehouse in the same place.'
    }
  },
  {
    name: 'Santa Ana Country Club',
    slug: 'santa-ana-country-club',
    url: 'https://santaanacountryclubcr.com/',
    classification: ['hospitalary'],
    location: 'Santa Ana',
    area: '80.000 m²',
    opening: '2018',
    cover: '/projects/santa-ana-country-club/cover.webp',
    images: [
      '/projects/santa-ana-country-club/01.webp',
      '/projects/santa-ana-country-club/02.webp',
      '/projects/santa-ana-country-club/03.webp',
      '/projects/santa-ana-country-club/04.webp'
    ],
    front: {
      order: 3,
      visible: true,
      image: '/projects/santa-ana-country-club/front.webp'
    },
    grid: {
      order: 8,
      span: [6, 4],
      image: '/projects/santa-ana-country-club/grid.webp'
    },
    hero_description:
      'Santa Ana Country Club consiste en un club social y deportivo, un lugar de 8 hectáreas de terreno ubicado en Pozos de Santa Ana.',
    description:
      'Santa Ana Country Club es un innovador club privado que ofrece a sus socios una excepcional oferta gastronómica, acuática, de deportes de raqueta, de fitness y un lugar de conexión social.',
    full_description:
      'Tiene 14.000 metros cuadrados tendrá amplias zonas verdes y áreas protegidas, además de las siguientes amenidades: Canchas de Tenis, Cancha multiuso, Gimnasio, Área multiuso, Piscina semiolímpica de 8 carriles, Canchas de fútbol 5, Sauna, Baños a vapor, Spa, Voleibol de Arena, Senderos, Área de Yoga, Piscinas para niños, Playground al aire libre para niños, Vestidores (family room), Restaurante Principal, Sports Bar, Restaurant Casual, Deli y Café, business center, Sala de juegos y entretenimiento, Canchas duales de raquetbol y squash.',
    en: {
      hero_description:
        'Santa Ana Country Club consists of a social and sports club, an 8 hectare site located in Pozos de Santa Ana.',
      description:
        '      Santa Ana Country Club is an innovative private club that offers its members an exceptional gastronomic, aquatic, racquet sports, fitness and a place for social connection.',
      full_description:
        'It has 14,000 square meters will have ample green areas and protected areas, in addition to the following amenities: Tennis courts, Multipurpose court, Gymnasium, Multipurpose area, Semi-Olympic 8-lane swimming pool, 5-a-side soccer fields, Sauna, Steam baths, Spa, Sand Volleyball, Trails, Yoga area, Childrens pools, Outdoor playground for children, Dressing rooms (family room), Main Restaurant, Sports Bar, Casual Restaurant, Deli and Café, business center, Game and entertainment room, Dual racquetball and squash courts.'
    }
  },
  {
    name: 'Coyol Free Zone',
    slug: 'coyol-free-zone',
    classification: ['free_zone_industrial'],
    location: 'Coyol, Alajuela',
    area: '400.000 m²',
    opening: '2006',
    cover: '/projects/coyol-free-zone/cover.webp',
    images: [
      '/projects/coyol-free-zone/01.webp',
      '/projects/coyol-free-zone/02.webp',
      '/projects/coyol-free-zone/03.webp',
      '/projects/coyol-free-zone/04.webp'
    ],
    grid: {
      order: 9,
      span: [6, 3],
      image: '/projects/coyol-free-zone/grid.webp'
    },
    hero_description:
      'Ubicado en el Coyol de Alajuela, en un área de 107 hectáreas, Coyol Free Zone and Business Park reúne a las principales empresas multinacionales de dispositivos médicos.',
    description:
      ' Es un complejo inmobiliario de nueva generación, que impulsa la economía costarricense promoviendo el capital humano, las prácticas de buen vecino y el desarrollo sostenible mediante correctas políticas ambientales.',
    full_description:
      'La Zona Franca Coyol es la primera y la mejor en soluciones de ventanilla única. Ofreciendo a nuestros clientes la comodidad de un proveedor de servicios único,\nCFZ coordina con un concepto de libro abierto todos los procesos de pre-construcción, ejecución, control presupuestario y entrega\na través de un experimentado equipo de gestión de la construcción.  Como resultado, nuestros clientes recibirán un edificio "llave en mano" hecho a medida.',

    en: {
      hero_description:
        'Located in Coyol de Alajuela, in an area of 107 hectares, Coyol Free Zone and Business Park gathers the main multinational companies of medical devices.',
      description:
        'It is a new generation real estate complex that boosts the Costa Rican economy by promoting human capital, good neighbor practices and sustainable development through correct environmental policies.',
      full_description:
        'Coyol Free Trade Zone is the first and best in one-stop solutions. Offering our clients the convenience of a single service provider, Coyol Free Zone coordinates with an open-book concept all pre-construction, execution, budget control and delivery processes through an experienced construction management team.  As a result, our clients will receive a tailor-made turnkey building.'
    }
  },
  {
    name: 'Azenza Towers',
    slug: 'azenza-towers',
    classification: ['residential'],
    url: 'http://azenzatowers.com',
    location: '150 Este del Hotel San José Palacio, San José',
    area: '38.000m²',
    opening: '2015',
    cover: '/projects/azenza-towers/03.png',
    images: [
      '/projects/azenza-towers/01.jpg',
      '/projects/azenza-towers/02.webp',
      '/projects/azenza-towers/03.png',
      '/projects/azenza-towers/04.webp'
    ],
    grid: {
      image: '/projects/azenza-towers/03.png',
      order: 10,
      span: [6, 4]
    },
    hero_description:
      'Proyecto residencial de dos torres de departamentos que juntas cubrirán un área de construcción de 40.000m2 a través de sus 154 departamentos.',
    description:
      'AZENZA son dos torres de apartamentos de 23 niveles con características estéticas y arquitectónicas única Este complejo habitacional en vertical busca crear una simbiosis entre diferentes materiales en su forma más pura, creando espacios elegantes y genuinos, unificados por la naturaleza.',
    full_description:
      'Azenza Towers une lo mejor de dos mundos: la comodidad de vivir en el corazón de la capital con la comodidad de un proyecto diseñado para la vida moderna. La experiencia Azenza le permite integrar a sus seres queridos y sus mascotas en su estilo de vida en un entorno sostenible y que admite mascotas. En tu nuevo hogar encontrarás espacio para desarrollar tu vida profesional y personal a través de nuestras comodidades. Queremos que su nuevo apartamento lo haga sentir cómodo, inspirado y productivo.',
    en: {
      hero_description:
        'Residential project of two apartment towers that together will cover a construction area of 40,000m2 through its 154 apartments.',
      description:
        'AZENZA are two 23-story apartment tower with unique aesthetic and architectural characteristics. This vertical housing complex seeks to create a symbiosis between different materials in their purest form, creating elegant and genuine spaces, unified by nature.',
      full_description:
        'Azenza Towers brings together the best of two worlds: the convenience of living in the heart of the capital with the comfort of a project designed for modern living. The Azenza experience allows you to integrate your loved ones and pets into your lifestyle in a sustainable and pet-friendly environment. In your new home you will find space to develop your professional and personal life through our amenities. We want your new apartment to make you feel comfortable, inspired and productive.'
    }
  },
  {
    name: 'Hacienda Espinal',
    slug: 'hacienda-espinal',
    classification: ['residential'],
    url: 'https://haciendaespinal.com/',
    location: 'Km 17,5 Ruta 27, San José-Caldera',
    area: '112 hectáreas (total proyecto)',
    opening: '2011',
    cover: '/projects/hacienda-espinal/cover.webp',
    images: [
      '/projects/hacienda-espinal/01.webp',
      '/projects/hacienda-espinal/02.webp',
      '/projects/hacienda-espinal/03.webp',
      '/projects/hacienda-espinal/04.webp'
    ],
    grid: {
      order: 11,
      span: [3, 3],
      image: '/projects/hacienda-espinal/grid.webp'
    },
    hero_description:
      'Modelo de desarrollo único en el país, ubicado en el km 17,5 Ruta 27, San José-Caldera, en un terreno de 112 hectárea.',
    description:
      'Integra oficinas, comercio, supermercado, Colegio Country Day School, 4.8 km de ciclovía, amplias áreas de esparcimiento, piscinas, canchas de tenis, anfiteatro y un Town Center. La primera etapa comprende 70 lotes desde 1000 m2, la segunda y tercera etapa lotes desde los 600 m2. Posteriormente se comercializarán townhouses, duplex y apartamentos.',
    full_description:
      'Su operación se sustenta en una rigurosa estrategia de sostenibilidad, por lo cual recibió el premio de construcción sostenible 2014 y, en el 2015, la acreditación marca país Esencial Costa Rica.\n\nLa experiencia de más de 20 años en proyectos arquitectónicos, convirtieron a Andrés Morales en la mejor elección para el diseño y desarrollo de Hacienda Espinal. En este proyecto, su meta fue garantizar a sus residentes un estilo de vida sofisticado y armonioso con el medio ambiente.',
    en: {
      hero_description:
        'Unique development model in the country, located at km 17.5, Route 27, San José-Caldera, on a 112-hectare site.',
      description:
        'It integrates offices, commerce, supermarket, Country Day School, 4.8 km of bicycle path, ample recreational areas, swimming pools, tennis courts, amphitheater and a Town Center. The first stage comprises 70 lots starting at 1000 m2, the second and third stage lots starting at 600 m2. Subsequently, townhouses, duplexes and apartments will be marketed.',
      full_description:
        'Its operation is based on a rigorous sustainability strategy, for which it received the 2014 sustainable construction award and, in 2015, the Esencial Costa Rica country brand accreditation.The experience of more than 20 years in architectural projects made Andrés Morales the best choice for the design and development of Hacienda Espinal. In this project, his goal was to guarantee its residents a sophisticated lifestyle in harmony with the environment.'
    }
  },
  {
    name: 'Azul Paraíso',
    slug: 'azul-paraiso',
    url: 'http://azulparaiso.com/',
    classification: ['residential'],
    location: 'Playa Ocotal, Guanacaste',
    area: '3,4 hectáreas',
    opening: '2008',
    cover: '/projects/azul-paraiso/cover.webp',
    images: [
      '/projects/azul-paraiso/01.webp',
      '/projects/azul-paraiso/02.webp',
      '/projects/azul-paraiso/03.png',
      '/projects/azul-paraiso/04.jpg'
    ],
    grid: {
      order: 12,
      span: [3, 3],
      image: '/projects/azul-paraiso/grid.webp'
    },
    hero_description:
      'Situado en la costa del Pacífico Norte de Costa Rica, en la playa de Ocotal, el complejo residencial de Azul Paraíso está situado en 3,4 hectáreas y ofrece todo lo que necesita para relajarse. Unas excepcionales vistas al océano y unas comodidades únicas le sorprenderán durante su estancia en Azul Paraíso. Dedique su tiempo a crear auténticos recuerdos inspirados en la tranquilidad de la vida junto al mar, a disfrutar de un momento de relax admirando nuestras vistas panorámicas o a despertar su lado aventurero nadando, buceando o haciendo snorkel en las aguas cristalinas de Ocotal.',
    description:
      'La I Etapa consiste en 10 edificios, cada uno con una unidad por piso, para un total de 28 residencias. Los edificios están distribuidos por la propiedad en pares; cada uno con su propia piscina y vistas al mar. Azul Paraíso ofrece exclusivas residencias de 2, 3 y 4 dormitorios.',
    full_description:
      'Durante el primer trimestre del 2024 iniciará la construcción de la II etapa del proyecto Azul Paraíso, conformado por 59 apartamentos distribuidos en dos torres y se ubicará, junto al resto del complejo habitacional actual.',
    en: {
      hero_description:
        'Located on the North Pacific coast of Costa Rica, on Ocotal beach, the Azul Paraiso residential complex is situated on 3.4 hectares and offers everything you need to relax. Exceptional ocean views and unique amenities will amaze you during your stay at Azul Paraiso. Spend your time creating authentic memories inspired by the tranquility of life by the sea, enjoy a relaxing moment admiring our panoramic views or awaken your adventurous side by swimming, diving or snorkeling in the crystal clear waters of Ocotal.',
      description:
        'Phase I consists of 10 buildings, each with one unit per floor, for a total of 28 residences. The buildings are distributed throughout the property in pairs; each with its own pool and ocean views. Azul Paraiso offers exclusive 2, 3 and 4 bedroom residences.',
      full_description:
        'During the first quarter of 2024, construction will begin on the second phase of the Azul Paraíso project, which will consist of 59 apartments distributed in two towers and will be located next to the rest of the current housing complex.'
    }
  },

  {
    name: 'CITANA',
    slug: 'citana',
    classification: ['commercial'],
    location: 'Sabana Norte',
    area: '60.000m2',
    opening: 'Próximamente',
    cover: '/projects/citana/01.jpg',
    images: [
      '/projects/citana/01.jpg',
      '/projects/citana/02.jpg',
      '/projects/citana/03.jpg'
    ],
    grid: {
      order: 13,
      span: [6, 40],
      image: '/projects/citana/covercitana.jpeg'
    },
    hero_description:
      'Nuevo proyecto de uso mixto al costado norte del Parque La Sabana que combinará una oferta comercial en los primeros niveles con espacios de oficina en los niveles superiores, todos con vistas al parque, el este de la ciudad y las montañas del Valle Central.',
    description:
      'El edificio de 20 niveles y 3 sótanos contará  con una arquitectura biofílica conectando así con la naturaleza. Plazas abiertas, de doble altura libre en los primeros niveles, 8 plataformas de parqueo y 10 niveles de oficina. Fachadas especializadas para controlar temperatura interna del edificio y hacer más eficiente el uso de aires acondicionados.',
    full_description:
      'El edificio contará con certificaciones de Bandera Azul Ecológica y LEED Gold.',
    en: {
      opening: 'Coming soon',
      hero_description:
        'New mixed-use project on the north side of La Sabana Park that will combine a commercial offering on the first levels with office space on the upper levels, all with views of the park, the east side of the city and the Central Valley mountains.​',
      description:
        'The building of 20 levels and 3 basements will have a biophilic architecture connecting with nature. Double height retail area with direct access to the Avenue of the Americas. Specialized facades to control the building`s internal temperature and make the use of air conditioning more efficient.​',
      full_description:
        'The building will have Ecological Blue Flag and LEED certifications.'
    }
  },
  {
    name: 'Viva Residence',
    slug: 'viva-residence',
    classification: ['hotel_resort'],
    location: 'Escazú, San José',
    area: '8.658 m²',
    opening: '2011',
    cover: '/projects/viva-residence/cover.webp',
    images: [
      '/projects/viva-residence/01.webp',
      '/projects/viva-residence/02.webp',
      '/projects/viva-residence/03.webp',
      '/projects/viva-residence/04.webp'
    ],
    grid: {
      order: 17,
      span: [3, 32],
      image: '/projects/viva-residence/grid.webp'
    },
    hero_description:
      'Novedoso concepto residencial en uno de los puntos de mayor plusvalía de Escazú.',
    description:
      'Exclusiva torre de 34 apartamentos, Casa Club, gimnasio, lounge privado y amplias zonas verdes. 6 diseños diferentes de apartamentos a elegir.',
    en: {
      hero_description:
        'New residential concept in one of Escazú´s most sought-after locations.',
      description:
        'Exclusive tower of 34 apartments, Club House, gym, private lounge and large green areas. 6 different apartment designs to choose from.'
    }
  },
  {
    name: 'Condominio La Cañada',
    slug: 'condominio-la-canada',
    classification: ['residential'],
    location: 'San Rafael, Alajuela',
    area: '59.581 m²',
    opening: '1999',
    cover: '/projects/condominio-la-canada/cover.webp',
    images: [
      '/projects/condominio-la-canada/01.webp',
      '/projects/condominio-la-canada/02.webp',
      '/projects/condominio-la-canada/03.webp',
      '/projects/condominio-la-canada/04.webp'
    ],
    grid: {
      order: 16,
      span: [3, 32],
      image: '/projects/condominio-la-canada/grid.webp'
    },
    hero_description:
      'Extraordinario proyecto de casas y lotes en condominio ubicado 1 kilómetro al oeste del Centro Comercial Ribera de Belén, Heredia.',
    description:
      'Cuenta con 56 lotes entre los 1,138m2 y los 1, 183,48m2. Casas con áreas entre los 250 y 450 m2.',
    full_description:
      'El Condominio La Cañada se encuentra en uno de los puntos de mayor plusvalía de Belén, es el lugar perfecto para aprovechar e invertir en su futuro.',
    en: {
      hero_description:
        'Extraordinary project of houses and lots in condominium located 1 kilometer west of the Ribera de Belen Shopping Center, Heredia.',
      description:
        'It has 56 lots between 1,138m2 and 1,183.48m2. Houses with areas between 250 and 450 m2.',
      full_description:
        'La Cañada Condominium is located in one of the most valuable areas of Belen, it is the perfect place to take advantage and invest in your future.'
    }
  },

  {
    name: 'Universidad Latina',
    slug: 'universidad-latina',
    classification: ['commercial'],
    location: 'Heredia',
    area: '37.000 m²',
    opening: '2019',
    cover: '/projects/universidad-latina/cover.webp',
    images: [
      '/projects/universidad-latina/01.webp',
      '/projects/universidad-latina/02.webp',
      '/projects/universidad-latina/03.webp',
      '/projects/universidad-latina/04.webp'
    ],
    grid: {
      order: 15,
      span: [6, 8],
      image: '/projects/universidad-latina/grid.webp'
    },
    hero_description:
      'El campus integra de forma ordenada, la infraestructura educativa y recreativa para facilidad y comodidad de los estudiantes.',
    description:
      'Consiste en un edificio de 15.000 m2, distribuido en cinco niveles y que integra aulas, talleres, laboratorios, biblioteca, oficinas administrativas y una plaza de comidas, es el primer centro educativo de enseñanza superior privada, diseñado bajo este formato.',
    en: {
      hero_description:
        'The campus integrates the educational and recreational infrastructure in an orderly manner for the ease and comfort of the students.',
      description:
        'It consists of a 15,000 m2 building, distributed in five levels and integrating classrooms, workshops, laboratories, library, administrative offices and a food court, it is the first private higher education center designed under this format.',
      full_description: ''
    }
  }
];
// order: 7, span: [3, 3],
// order: 10, span: [3, 3],
export default projects;
