export const residential = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="162.917"
    height="206.285"
    viewBox="0 0 162.917 206.285"
  >
    <g
      id="noun_residential_house_1753425"
      data-name="noun_residential house_1753425"
      transform="translate(1.5 1.5)"
    >
      <path
        id="Path_8445"
        data-name="Path 8445"
        d="M76.92,115.528,63.367,110.7V99.862a13.552,13.552,0,0,0,10.842-13.39c0-6.071-4.8-24.394-13.552-24.394S47.1,80.4,47.1,86.472A13.553,13.553,0,0,0,57.949,99.754l0,9-21.684-7.725V88.912A13.552,13.552,0,0,0,47.1,75.63c0-6.071-4.8-24.394-13.552-24.394S20,69.559,20,75.63A13.553,13.553,0,0,0,30.845,88.912l0,10.164-8.131-2.9V219.285H179.917V16h-103ZM52.526,86.472c0-7.454,5.421-18.973,8.131-18.973s8.131,11.519,8.131,18.973a8.131,8.131,0,1,1-16.263,0ZM25.421,75.63c0-7.454,5.421-18.973,8.131-18.973s8.131,11.519,8.131,18.973a8.131,8.131,0,1,1-16.263,0Zm16.263,89.445H71.5v48.788H41.684Zm65.051-5.421H41.684v-5.421h65.051ZM76.92,213.864V165.076h29.815v48.788Zm97.577,0H112.156V148.813H36.263v65.051H28.131V103.873L174.5,156.131ZM82.341,21.421H174.5V150.385L82.341,117.1Z"
        transform="translate(-20 -16)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <path
        id="Path_8446"
        data-name="Path 8446"
        d="M47,44.684V60.947H79.526V23H47ZM74.1,55.526H52.421V50.1H74.1Zm-21.684-27.1H74.1V44.684H52.421Z"
        transform="translate(26.183 -4.027)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <path
        id="Path_8447"
        data-name="Path 8447"
        d="M62,44.684V60.947H94.526V23H62ZM89.1,55.526H67.421V50.1H89.1Zm-21.684-27.1H89.1V44.684H67.421Z"
        transform="translate(51.839 -4.027)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_328"
        data-name="Rectangle 328"
        width="5.487"
        height="5.487"
        transform="translate(43.394 171.146)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_329"
        data-name="Rectangle 329"
        width="4.703"
        height="5.487"
        transform="translate(59.856 171.146)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <path
        id="Path_8448"
        data-name="Path 8448"
        d="M99.367,69H56v37.946H99.367ZM93.946,90.142H80.394V74.421H93.946ZM74.973,74.421V90.142H61.421V74.421ZM61.421,95.563H74.973v5.963H61.421Zm18.973,5.963V95.563H93.946v5.963Z"
        transform="translate(41.577 74.655)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
    </g>
  </svg>
);
