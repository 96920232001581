import _ from 'lodash';

export const toPercent = (int) => {
  return int * 100;
};

export const toDecimal = (int) => {
  return (int === 0 || isValidate(int)) ? 0 : int / 100;
};

export const isValidate = (e) => {
  return _.isNaN(e) || _.isNil(e) || e.length === 0;
};

export const isValidMail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return !re.test(email.toLowerCase());
};
