export const commercial = ({ ...props }) => (
  <svg
    {...props}
    id="Layer_7"
    data-name="Layer 7"
    xmlns="http://www.w3.org/2000/svg"
    width="243.959"
    height="209.11"
    viewBox="0 0 243.959 209.11"
  >
    <path
      id="Path_8456"
      data-name="Path 8456"
      d="M244.139,135.511l-20.268-14.533,2.414-15.646,0,0,2.2-14.09a4.358,4.358,0,0,0-3.318-4.914L167.5,72.9l2.981-29.886a4.338,4.338,0,0,0-2.646-4.45L86.465,4.343a4.356,4.356,0,0,0-6.05,4.007l-.032,16.672L25.509,56.666l-1.361.83a4.343,4.343,0,0,0-2.017,4.514L28.947,99.1l0,0,3.465,18.918L4.085,135.333A4.36,4.36,0,0,0,2,139.051v69.7a4.354,4.354,0,0,0,4.356,4.356H241.6a4.354,4.354,0,0,0,4.356-4.356v-69.7a4.359,4.359,0,0,0-1.821-3.54Zm-87.7,51.11,1.837-18.437,53.465-25.694-3.17,20.649ZM43.215,128.609l-5.071-27.676,7.015-3.046,35.115-15.25,0,1.678a4.251,4.251,0,0,0,.143.678l-.049,24.482c0,.084.046.158.051.241l-.005,2.76-9.031,3.918ZM156.364,98.5l-67.235,6.2V88.253l68.946-7.1Zm-67.235,16.83v-1.877l66.41-6.121-1.846,18.51-64.564,4.877Zm-8.737,6.648-.027,13.432c0,.085.046.159.051.243l-.047,17.4L50.133,166.2l-5.309-28.792Zm80.728,17.688L216.4,112.284l-3.051,19.768L159.29,158.029Zm56.907-37.917-55.942,27.714,2.644-26.522c.007-.068-.028-.131-.024-.2l2.077-21.066,52.47,12.212Zm-56.531-56.4-2.689,26.955L89.128,79.481V27.52L89.1,27.4l.024-12.485ZM80.365,35.079l-.072,38.05L36.528,92.134,31.244,63.409ZM10.713,141.493,34.1,127.21l1.531,8.361v.009l6.923,37.576c.011.087-.013.171,0,.258L48.236,204.4H10.713ZM51.75,175.242l28.592-12.425L80.232,204.4H57.1Zm80.942-5.7H106.554v-8.713h26.139ZM106.554,204.4V178.259h26.139V204.4Zm34.851,0V156.477a4.354,4.354,0,0,0-4.356-4.356H102.2a4.354,4.354,0,0,0-4.356,4.356V204.4H89.128V156.145c0-.047-.032-.086-.034-.133l.034-.113V139.463l63.738-4.815L145.915,204.4Zm17.426,0h-4.16l.774-7.77,51.59-23.239L202.259,204.4Zm78.416,0H211.073l5.753-37.349-.1-.015c.018-.086.087-.148.1-.235l4.781-31.147v0l.775-5.02,14.864,10.661Z"
      transform="translate(-2 -4)"
      fill="currentColor"
    />
  </svg>
);
