import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en } from './translations/en';
import { es } from './translations/es';

i18n.use(LanguageDetector).init({
  resources: {
    es: {
      translations: es
    },
    en: {
      translations: en
    }
  },
  debug: true,
  ns: ['translations'],
  defaultNS: 'translations',
  react: {
    wait: true
  }
});

export default i18n;
