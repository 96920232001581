import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { assetUrl } from '../../utils/assets';
import { Logo as LogoBase } from '../../components/Icon';
import styled from 'styled-components';

const Logo = styled(LogoBase)`
  margin: 20px auto;
  height: 58px;
`;

const Footer = () => {
  const { t } = useTranslation();

  const contactInfo = {
    address: t('footer.address'),
    phoneNumber: '+506 2205-3600',
    email: 'info@garnier.cr',
    employmentBoard: t('footer.employmentBoard'),
    employmentEmail: ' empleos@garnier.cr'
  };
  const socialMedia = {
    ytLink: 'https://www.youtube.com/user/GarnieryGarnier',
    lkInLink:
      'https://www.linkedin.com/company/garnier&garnier-desarrollos-inmobiliarios/',
    fbLink: 'https://www.facebook.com/garnierygarnier'
  };

  const [isMobile, setIsMobile] = useState(false);

  const listenedResize = () => {
    const widthWindow = window.innerWidth;

    if (widthWindow >= 780) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    listenedResize();

    window.addEventListener('resize', listenedResize);
  }, []);
  return (
    <footer className="footer">
      <div className="container">
        <div className="contact-info-content wrap-info-footer">
          <div className="content-info">
            <div className="wrap-icon-info">
              <img src={assetUrl('footer/Ubicacion.svg')} />
            </div>
            <p>{contactInfo.address}</p>
          </div>
          <div className="wrap-paragrahp">
            <div className="content-info">
              <div className="wrap-icon-info">
                <img src={assetUrl('footer/Telefono.svg')} />
              </div>
              <p>{contactInfo.phoneNumber}</p>
            </div>

            {isMobile && (
              <>
                <div className="content-info">
                  <div className="wrap-icon-info">
                    <img src={assetUrl('footer/Email.svg')} />
                  </div>
                  <p>{contactInfo.email}</p>
                </div>
              </>
            )}

            {!isMobile && (
              <>
                <div className="content-info">
                  <div className="wrap-icon-info">
                    <img src={assetUrl('footer/Email.svg')} />
                  </div>
                  <p>{contactInfo.email}</p>
                </div>
              </>
            )}
            {isMobile && (
              <>
                <div className="content-info">
                  <p className="green-title">{contactInfo.employmentBoard}</p>

                  <p>{contactInfo.employmentEmail}</p>
                </div>
              </>
            )}
            {!isMobile && (
              <>
                <div className="content-info">
                  <p className="green-title">{contactInfo.employmentBoard}</p>

                  <p>{contactInfo.employmentEmail}</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="main-logo-content wrap-info-footer">
          <Logo primary />
          <h3>GARNIER</h3>
          <p>ENHANCING LAND</p>
        </div>
        <div className="social-media-content wrap-info-footer">
          <div className="social-network">
            <a
              href={socialMedia.ytLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={assetUrl('footer/Youtube.svg')} />
            </a>
            <a
              href={socialMedia.lkInLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={assetUrl('footer/Linkedin.svg')}
                className="space-between"
              />
            </a>
            <a
              href={socialMedia.fbLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={assetUrl('footer/Facebook.svg')}
                className="space-between"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="p-copyright">
        <p>© Copyright 2021. </p>
        <p className="green-text">
          Garnier & Garnier Desarrollos inmobiliarios
        </p>
      </div>
    </footer>
  );
};

export default Footer;
