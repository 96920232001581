export const opening = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="88.992"
    height="93.346"
    viewBox="0 0 88.992 93.346"
  >
    <g id="Group_73" data-name="Group 73" transform="translate(0.536 0.547)">
      <path
        id="Path_8401"
        data-name="Path 8401"
        d="M95.438,73.634,78.74,62.165c-.2-1.242-.423-2.509-.673-3.838C77.081,53.1,76.063,47.7,75.317,42.354l.65-.447h0L94.5,29.178l-11.189-4.27-9.04,5.225C74.387,21.585,75.964,14.3,79.1,7.948A1.562,1.562,0,0,0,76.922,5.9L62.878,14.01a1.565,1.565,0,0,0-.7.846c-2.4,6.994-3.264,14.6-2.737,23.831l-.466.269c-.024.015-.043.037-.066.053l-7.54,4.357-10.7-7.347A18.124,18.124,0,1,0,17.37,39.254L37.927,51.138,16.365,63.6A18.125,18.125,0,0,0,26.2,96.955h0A18.128,18.128,0,0,0,39.736,66.794l11.549-7.932,12.293,7.106c1.113,7.149,2.118,14.6,2.576,22.716a1.562,1.562,0,0,0,.666,1.194l11.219,7.834a1.562,1.562,0,0,0,2.456-1.28c0-1.851.027-3.672.054-5.511.071-4.743.139-9.582-.232-15.285l3.934,2.272ZM64.951,16.421l9.457-5.46a58.1,58.1,0,0,0-3.278,20.979l-8.659,5A57.046,57.046,0,0,1,64.951,16.421ZM18.993,36.587A14.982,14.982,0,1,1,37.2,35.093L35.74,36.42,48.375,45.1l-7.33,4.236Zm17.27,31.13A15.005,15.005,0,0,1,26.2,93.832h0a15,15,0,0,1-8.207-27.568L61.8,40.945c.008-.005.019-.006.026-.011L73.487,34.2c.021-.011.034-.029.055-.043l10.045-5.8,4.236,1.617L34.8,66.392ZM54.136,56.9l5.711-3.923,1.838,1.263c.025.154.052.315.076.47q.269,1.665.546,3.371l.007.047.029.18q.3,1.823.6,3.681ZM77.423,90.872c-.014.859-.025,1.715-.035,2.574l-8.16-5.7a280.789,280.789,0,0,0-3.664-29.107l-.177-1.095c-.419-2.57-.824-5.078-1.2-7.548l8.273-5.683C73.187,49.227,74.1,54.136,75,58.907A141.158,141.158,0,0,1,77.423,90.872ZM79.381,66.4l9.379,6.443-4.236,1.618L80.011,71.85C79.847,70.12,79.641,68.31,79.381,66.4Z"
        transform="translate(-8.05 -5.693)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <path
        id="Path_8402"
        data-name="Path 8402"
        d="M31.933,14.733a10.308,10.308,0,0,0-14.086,3.774,9.663,9.663,0,0,0-.324,9.98A11.167,11.167,0,0,0,27.1,33.876h0a9.758,9.758,0,0,0,8.607-5.058A10.322,10.322,0,0,0,31.933,14.733ZM33,27.257a6.622,6.622,0,0,1-5.9,3.5h0a8.1,8.1,0,0,1-6.869-3.827c-.829-1.436-1.447-3.792.323-6.856A7.187,7.187,0,1,1,33,27.257Z"
        transform="translate(-7.707 -5.375)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <path
        id="Path_8403"
        data-name="Path 8403"
        d="M30.936,67.337c-.164-.092-.324-.177-.555-.291a10.326,10.326,0,0,0-4.458-1.012,10.764,10.764,0,0,0-9.181,5.286,9.939,9.939,0,0,0,.2,10.157,10.312,10.312,0,1,0,13.991-14.14ZM29.469,82.546a7.183,7.183,0,0,1-9.818-2.63,6.8,6.8,0,0,1-.2-7.033,7.589,7.589,0,0,1,6.475-3.724,7.233,7.233,0,0,1,3.131.717c.121.061.237.122.352.185a7.19,7.19,0,0,1,.062,12.484Z"
        transform="translate(-7.742 -3.185)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
      <ellipse
        id="Ellipse_4"
        data-name="Ellipse 4"
        cx="2.129"
        cy="2.129"
        rx="2.129"
        ry="2.129"
        transform="translate(41.388 42.822)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      />
    </g>
  </svg>
);
