import styled from 'styled-components';
import { Link as LinkBase, useHistory } from 'react-router-dom';

export const Link = styled(LinkBase)`
  display: inline-block;
  border: 3px solid ${({ theme }) => theme.$primary};
  color: ${({ theme }) => theme.$primary};
  padding: 0.75rem 1.5rem;
  margin: 1em 10rem;
  font-size: 5rem;
  border-radius: ${({ theme }) => theme.$roundnessSmall};
`;

export const LinkButton = styled(LinkBase)`
  display: inline-block;
  border: 3px solid ${({ theme }) => theme.$primary};
  color: ${({ theme }) => theme.$primary};
  padding: 1.5rem 2.5rem;
  font-size: 3rem;
  font-family: ${({ theme }) => theme.$fontBold};
  border-radius: ${({ theme }) => theme.$roundnessSmall};

  @media (max-width: ${({ theme }) => theme.$phone}) {
    font-size: 2rem;
    background-color: ${({ theme }) => theme.$background};
  }
`;

export const Anchor = ({ hash, pathName, onClick, children }) => {
  const history = useHistory();
  return (
    <a
      href="#"
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        } else if (hash) {
          const el = document.querySelector(hash);
          el.scrollIntoView();
        } else if (pathName) {
          history.push(pathName);
        }
      }}
    >
      {children}
    </a>
  );
};
