import styled from 'styled-components';
import { useState } from 'react';
import { Card } from '../../../components/Card';
import { useTranslation } from 'react-i18next';
import { assetUrl } from '../../../utils/assets';
import { Text } from '../../../components/typography';

const Container = styled.div`
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: minmax(50rem, max-content) minmax(80rem, 80vh) minmax(
      40rem,
      max-content
    );
  padding: ${({ theme }) => theme.$spacing};
  @media (max-width: ${({ theme }) => theme.$tablet}) {
    grid-template-rows: 30vh 80vh max-content;
  }
  @media (max-width: ${({ theme }) => theme.$phone}) {
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: max-content max-content repeat(10, 8vh) max-content;
    padding: ${({ theme }) => theme.$mobileSpacing};
  }
`;

const ProjectType = styled(Card).attrs({ wide: true })`
  grid-column: span 6;
  @media (max-width: ${({ theme }) => theme.$phone}) {
    grid-column: span 3;
  }
`;
const ProjectLocation = styled(Card)`
  grid-column: span 2;
  @media (max-width: ${({ theme }) => theme.$phone}) {
    grid-column: span 1;
    order: -1;
    max-width: 20vw;
  }
`;

const ProjectArea = styled(Card)`
  grid-column: span 2;
  @media (max-width: ${({ theme }) => theme.$phone}) {
    max-width: 20vw;
    grid-column: span 1;
    order: -1;
  }
`;

const ProjectOpening = styled(Card)`
  grid-column: span 2;
  @media (max-width: ${({ theme }) => theme.$phone}) {
    max-width: 20vw;
    grid-column: span 1;
    order: -1;
  }
`;

const ProjectImage = styled.div`
  background: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 0.3ms ease-in-out;
  grid-column: span ${({ collapsed }) => (collapsed ? '1' : '9')};

  @media (max-width: ${({ theme }) => theme.$phone}) {
    grid-column: span 3;
    grid-row: span ${({ collapsed }) => (collapsed ? '1' : '7')};
  }
`;

const ProjectDescription = styled(Text)`
  align-self: center;
  line-height: 2em;
  letter-spacing: 0.2em;
  grid-column: 4 / span 9;
  font-size: 1.2em;

  @media (max-width: ${({ theme }) => theme.$phone}) {
    grid-column: span 3;
  }
`;

export const Body = ({
  location,
  area,
  opening,
  description,
  classification,
  images,
  full_description
}) => {
  const { t } = useTranslation();
  const [activeImage, setActiveImage] = useState(3);

  return (
    <Container>
      <ProjectType
        title={t(`project.type.${classification?.[0]}`)}
        icon={classification?.[0]}
        description={description}
      />
      <ProjectLocation
        icon="location"
        title={t('project.detail.location')}
        description={location}
      />
      <ProjectArea
        icon="area"
        title={t('project.detail.area')}
        description={area}
      />
      <ProjectOpening
        icon="opening"
        title={t('project.detail.opening')}
        description={opening}
      />
      {images?.map((image, idx) => (
        <ProjectImage
          key={idx}
          src={assetUrl(image)}
          collapsed={activeImage !== idx}
          onClick={() => setActiveImage(idx)}
        />
      ))}
      <ProjectDescription>{full_description}</ProjectDescription>
    </Container>
  );
};
