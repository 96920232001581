import i18next from 'i18next';
import { HashLink as Link } from 'react-router-hash-link';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { assetUrl } from '../../utils/assets';
import { Logo } from '../../components/Icon';
import { DarkModeSwitch } from '../../components/DarkModeSwitch';

const MainMenu = ({ fixed }) => {
  const [, updateState] = useState();
  const { t, i18n } = useTranslation();
  const [scroll, setScroll] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isMovil, setIsMovil] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    const heightWindow = window.innerHeight;
    const widthWindow = window.innerWidth;

    if (offset > heightWindow && widthWindow > 768) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const listenedResize = () => {
    const widthWindow = window.innerWidth;

    if (widthWindow > 780) {
      setOpenMenu(false);
      setIsMovil(false);
    } else {
      setIsMovil(true);
    }
  };

  useEffect(() => {
    listenedResize();
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', listenedResize);
  }, []);

  const isMenuVisible = () => (fixed && !isMovil) || scroll || openMenu;

  const toggleLanguage = (e) => {
    e.preventDefault();
    i18next.changeLanguage(i18n.language === 'es' ? 'en' : 'es');
  };

  const toggleTheme = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle('invert');
    updateState(document.documentElement.classList.contains('invert'));
  };

  const menu = [
    {
      pathname: '/',
      hash: 'aboutUs',
      label: t('menu.aboutUs')
    },
    {
      pathname: '/',
      hash: 'team',
      label: t('menu.team')
    },
    {
      hash: '#top',
      pathname: '/portfolio',
      label: t('menu.projects')
    },
    {
      pathname: '/',
      hash: '#sustainability',
      label: t('menu.sustainability')
    },
    {
      pathname: '/',
      hash: '#contactUs',
      label: t('menu.contactUs')
    },
    {
      url: '#',
      label: i18n.language === 'en' ? t('spanish') : t('english'),
      onClick: toggleLanguage
    }
  ];

  return (
    <>
      {isMovil && (
        <>
          <img
            src={assetUrl('/menu/menu.svg')}
            className="menu-tab"
            onClick={() => setOpenMenu(!openMenu)}
          />
          <img src={assetUrl('/logo-white.png')} className="logo-white" />
        </>
      )}
      <header
        className={`header main-menu ${isMenuVisible() ? 'fixed-menu' : ''}`}
      >
        <div
          className={`container content-header ${
            openMenu ? 'hamburguer-menu' : ''
          }`}
        >
          <Link to={{ pathname: '/', hash: '#' }}>
            <Logo primary={isMenuVisible()} />
          </Link>
          {isMovil && (
            <img
              src={assetUrl('/menu/close.svg')}
              className="menu-close"
              onClick={() => setOpenMenu(!openMenu)}
            />
          )}
          <nav className="main-nav">
            {menu.map(({ hash, pathname, onClick, label }, index) => (
              <Link
                scroll={(e) => {
                  setImmediate(() => {
                    const top =
                      e.getBoundingClientRect().top +
                      window.pageYOffset -
                      document.querySelector('.main-menu').offsetHeight +
                      10; // fix an issue in which it falls short to scroll to about us;
                    // setImmediate fixes some bug in safari on which a double click is required to scroll
                    window.scrollTo({ top, behavior: 'smooth' });
                  });
                }}
                to={onClick ? {} : { hash, pathname }}
                onClick={(e) => {
                  onClick && onClick(e);
                  isMovil && setOpenMenu((m) => !m);
                }}
                key={index}
              >
                {label}
              </Link>
            ))}
            <DarkModeSwitch onClick={toggleTheme} />
          </nav>
        </div>
      </header>
    </>
  );
};

export default MainMenu;
