/* eslint-disable indent */

import styled from 'styled-components';
import { area } from './area';
import { commercial } from './commercial';
import { free_zone_industrial } from './free_zone_industrial';
import { hotel_resort } from './hotel_resort';
import { location } from './location';
import { opening } from './opening';
import { residential } from './residential';
import { logo } from './logo';
import { hospitalary } from './hospitalary';

export const Icon = ({ icon, ...props }) => {
  const icons = {
    area,
    commercial,
    free_zone_industrial,
    hospitalary,
    hotel_resort,
    location,
    opening,
    residential,
    logo
  };

  if (icon in icons) {
    const Component = icons[icon];
    return <Component {...props} />;
  }
  return <></>;
};

export const Logo = styled(Icon).attrs({
  icon: 'logo'
})`
  display: block;
  height: 36px;
  color: ${({ theme, primary, dark }) =>
    primary ? theme.$primary : dark ? theme.$dark : theme.$white};
`;
