export const data = {
  team: {
    image: '/team/team.jpg'
  },
  members: [
    {
      name: 'Philippe Garnier',
      picture: '/team/philippe.png',
      title: 'CEO',
      description: `
Philippe Garnier es responsable de las decisiones estratégicas del
grupo, impulsando la creatividad, la innovación y la sostenibilidad.
Empezó su carrera en la industria de la construcción en el 2003. Desde
entonces ha liderado 40 desarrollos inmobiliarios que se traducen en más
de 1.650.000 m².

Se graduó como ingeniero industrial de *Purdue University* en Indiana,
Estados Unidos. Además, es máster en administración de negocios en
*Mcombs School of Business*, en la Universidad de Texas.

Es la columna vertebral de la planificación y visión del grupo, los
nuevos proyectos e inversiones estratégicas, convirtiendo la compañía en
un referente dentro de la industria inmobiliaria desde hace más de 25
años.

Tiene un alto sentido de solidaridad, colabora con diferentes
iniciativas de bien social, es co-fundador de la Fundación CRC Endurance
Más que Deporte, la cual creó con el fin de potenciar a jóvenes en
condición vulnerable a través del deporte.

Dedica todo el tiempo posible a disfrutar al máximo cada momento junto a
su familia y el deporte. En los ratos libres la guitarra eléctrica le
acompaña, practica ciclismo, triatlón y maratón.
      `,
      en: {
        title: 'Garnier & Garnier President and CEO',
        description: `
Philippe Garnier is responsible for the group’s long-term strategic
direction, and driving creativity, innovation, and sustainability throughout Garnier. 
He began his career in the construction industry in 2003. Since 
then, he has led the development of over 40 real estate projects, totaling over 1,650,000 square meters of new construction.
        
Philippe has a bachelor’s degree in industrial engineering from Purdue University in Indiana. 
Additionally, he holds a Master’s Degree in Business Administration from 
McCombs School of Business at the University of Texas.

He is the backbone for the group’s planning and vision of, 
new projects and strategic investments,turning the company 
into one of the major real estate developers in the country.
        
Philippe’s sense of solidarity runs throughout
everything he does, and he works with several non-for-profit initiatives. 
He is the co-founder of the CRC Endurance Sports
Foundation, created to boost morale and educational levels by
introducing organized sports in low-income youth groups 
throughout the country.

Philippe is dedicated to his family and recreational sport initiatives.
He spends his free time playing the electric guitar,cycling, triathlon, 
and competing in marathon.

`
      }
    },
    {
      name: 'Alberto Bonilla',
      picture: '/team/alberto.png',
      title: 'Gerente General',
      description: `
Ingeniero civil graduado de Tufts University en Boston, Massachusetts,
máster en administración de negocios con énfasis en finanzas y real
estate, del Stern School of Business en New York, percusionista desde
los cinco años cuando se integró a la Orquesta Sinfónica Juvenil y
maratonista. Así es Alberto Bonilla, gerente general de Garnier &
Garnier, quien trabaja para la firma desde el 2008.

Aquí lidera el desarrollo de todos los proyectos inmobiliarios de la
empresa. Además, es responsable de la coordinación directa con los
gerentes de proyectos, de ingeniería, finanzas, comercial y
sostenibilidad. También es miembro del comité ejecutivo de la empresa.

Actualmente, participa en la Junta Directiva del Consejo de Desarrollo
Inmobiliario de Costa Rica (CODI), es miembro activo en la Cámara
Costarricense de Construcción e ingeniero profesional (PE) certificado
vigente del estado de California, Estados Unidos; miembro de la Sociedad
Americana de Ingenieros Civiles (ASCE) y del Urban Land Institute (ULI).
`,
      en: {
        title: 'Garnier & Garnier General Manager',
        description: `
Alberto Bonilla is the General Manager of Garnier & Garnier, having been with the firm since 2008. 
Alberto holds a bachelor’s degree in Civil Engineering from Tufts University in Boston Massachusetts; as well as
an MBA, with emphasis in finance and real estate development, from the Stern School of Business NYU. 
Alberto has been a passionate drummer since the age of five when he joined the Costa Rican
Youth Symphony as well as an avid marathon runner.

At Garnier, Alberto heads the company’s RE development division, directly
coordinating with the project management engineering,
finance, sales & marketing, and sustainability temas. 
He is also a member of the company’s executive committee.

Alberto is licensed as a Professional Engineer (PE) in the state of
California, USA, and is Vice President of the Board of
Directors of the Real Estate Development Council of Costa Rica (CODI). 
He is an active member of the Costa Rican Chamber of Construction, and a member of the
American Society of Civil Engineers (ASCE) and of the Urban Land Institute (ULI).
`
      }
    },
    {
      name: 'Fernando Carazo',
      picture: '/team/fernando.png',
      title: 'Gerente General Zona Franca La Lima',
      description: `
Desde el año 2014, Fernando Carazo ocupa el cargo de gerente general de
Zona Franca La Lima. Cuenta con habilidades de negociación y de gestión
de equipos de trabajo. Posee un amplio conocimiento de la industria
inmobiliaria costarricense y del desarrollo y mantenimiento de
operaciones comerciales en otros países del continente americano.

Es máster en administración de negocios del INCAE, graduado en el campus
de Managua, Nicaragua. Además, es bachiller en administración de hoteles
y restaurantes de la Universidad Lynn, ubicada en Florida, Estados
Unidos, donde obtuvo su título con honores Magna Cum Laude, por su
destacado rendimiento académico.

En Zona Franca La Lima, es responsable de la gestión de presupuesto, del
diseño, de la implementación de la estrategia comercial del proyecto y
de la administración de los condominios. Asimismo, coordina la
integración del parque con los principales actores y organizaciones de
interés de la provincia.

Es apasionado del deporte y durante su tiempo libre, disfruta jugar
golf, squash y correr.
`,
      en: {
        title: 'General Manager, La Lima Free Trade Zone',
        description: `
Since 2014, Fernando Carazo has occupied the position of the La Lima Free Trade Zone General Manager. He has negotiation and work team management skills. He has a broad knowledge of the Costa Rican real estate industry and of developing and maintaining business operations in other countries in North and South America. 

He holds a master’s degree in Business Administration from the INCAE, and is a graduate of the Managua, Nicaragua campus. In addition, he holds a bachelor’s degree in hotel and restaurant management from Lynn University, located in Florida, the United States, where he received his degree with magna cum laude honors. 

At La Lima Free Trade Zone, he is responsible for handling the budgets, designing and implementing the project’s business strategy and for administering the condominiums. Likewise, he coordinates integrating the park with the main stakeholders and organizations of interest in the province. 

He is a devote of sports and during his free time he enjoys playing golf, and squash and running. 
. 
`
      }
    },
    {
      name: 'Andrea Hidalgo',
      picture: '/team/andrea.png',
      title: 'Gerente de Finanzas Corporativas',
      description: `
Con un fuerte enfoque en la obtención de resultados, desde hace 10 años, evalúa minuciosamente todos los procedimientos de la compañía para alcanzar la mayor eficiencia. Andrea Hidalgo, es la gerente corporativa de finanzas y responsable del área legal de Garnier & Garnier; y de la mano de la inteligencia artificial, implementó sistemas modernos para la automatización de procesos y tareas en la corporación.

Es máster en dirección de empresas con énfasis en finanzas, licenciada en contaduría pública de la Universidad de Costa Rica y tiene una licenciatura en derecho de la Universidad Internacional de las Américas. 

Como gerente de finanzas es la responsable de la definición y ejecución de la estructuración financiera, legal y fiscal de las empresas y proyectos inmobiliarios del grupo, además de encargarse de la relación directa con entidades bancarias así como de la estructuración del financiamiento de los proyectos inmobiliarios. 

Su mayor satisfacción es compartir tiempo en familia, en su tiempo libre, la lectura y la jardinería la acompañan.
`,
      en: {
        title: 'Corporate Finance Manager',
        description: `
With a dedicated focus on obtaining results, she has exhaustively evaluated all the company procedures since 10 years ago to gain the greatest efficiency. Andrea Hidalgo is the Corporate Finance Manager and responsible for the Garnier & Garnier legal area; hand-in-hand with artificial intelligence, she has implemented modern systems to automate processes and tasks in the Corporation.

She holds a master’s degree in business management with an emphasis on finance and a graduate degree in public accounting from the University of Costa Rica . She also holds a licentiate degree in law from the international University of the Americas. 

As the manager of finance, she is responsible for defining and executing financial, legal, and tax structuring for the group’s companies and real estate projects. In addition, she is in charge of a direct relationship with banking entities as well as of structuring the financing for the real estate projects. 

She receives the greatest satisfaction from spending time with her family. In her free time, she likes to read and spend time in her garden.`
      }
    },
    {
      name: 'Alvaro Ramírez',
      picture: '/team/alvaro.png',
      title: 'Gerente de Ingeniería',
      description: `
Llegó a Garnier & Garnier en el 2007, desde entonces se ha encargado de
la coordinación de 24 proyectos desarrollados por el grupo, que
representan más de 1MM de m^2^ de construcción. Álvaro Ramírez es
nuestro gerente de ingeniería. Lo acompañan más de 30 años de ejercicio
profesional en Colombia y Costa Rica y dentro sus logros está la
conformación del departamento que dirige, la tramitación efectiva de
permisos y el cumplimiento de metas en tiempo y plazo.

Se graduó como ingeniero civil de la Pontificia Universidad Javeriana de
Bogotá y posee un postgrado con especialización en gestión ambiental de
la Universidad Autónoma de Occidente, en Cali, Colombia.

Entre sus responsabilidades está dirigir, planear y controlar el departamento de ingeniería de proyectos; coordinar con los diseñadores, consultores y constructores para obtener el producto que el cliente necesita y maximizar así la rentabilidad del proyecto para el cliente y la empresa. Además de los trámites ante instituciones públicas.
`,
      en: {
        title: 'Engineering Manager',
        description: `
He came to Garnier & Garnier in 2007. Since then he has been in charge of coordinating 24 projects developed by the group. Those projects represent more than 1 square meters of construction. Álvaro Ramírez is our engineering manager.  He is backed by more than 30 years of professional experience in Colombia and Costa Rica. His achievements include putting together the department that he manages, effectively processing permits, and complying with goals as to time and deadlines.

He graduated as a civil engineer from the Pontificia Universidad Javeriana in Bogotá and he holds a post-graduate degree with a specialization in environmental management from the Universidad Autónoma de Occidente, in Cali, Colombia.

His responsibilities include directing, planning, and controlling the project engineering department; coordinating with designers, consultants, and builders to obtain the product that the client needs, and thus to maximize the project profitability for the customer and the company. This is in addition to processes with public institutions.
`
      }
    },
    {
      name: 'Ronny Rodriguez',
      picture: '/team/ronny.png',
      title: 'Gerente de Desarrollo',
      description: `
Tiene una década de ser parte del talento humano de Garnier & Garnier, ha ejecutado una gran cantidad de proyectos de todo tipo e industria, también ha dirigido la viabilidad financiera, técnica y legal de otros que están por iniciar. Ronny Rodríguez es el gerente de desarrollo del grupo. Se le conoce por su afición al fútbol en su tiempo libre, además de leer y compartir con su familia y amigos. Destina su conocimiento a la construcción de viviendas de bien social.

Es máster en finanzas y economía, así como en desarrollo sostenible del INCAE; es ingeniero civil de la Universidad Latina de Costa Rica y certificado como profesional de gestión de proyectos, del Project Management Institute (PMI).

Ronny es responsable de los resultados globales de los proyectos de Garnier, dirige a los gerentes de finanzas, ingeniería, ventas-marketing, legal, contabilidad y RSC. También, dirige a equipos técnicos de arquitectos, ingenieros, contratistas, y consultores en general. Tiene a cargo el modelo financiero, los presupuestos, los flujos de caja y los calendarios globales de los proyectos, así como el análisis estratégico, estudios de viabilidad y due diligence para nuevas inversiones.
`,
      en: {
        title: 'Development Manager',
        description: `
After being part of the Garnier & Garnier human talent team for a decade, Ronny has been involved in a large number of projects and all types of industry and has also directed financial, technical, and legal viability and other types of projects that are about to begin. Ronny Rodriguez is the group’s development manager. He is known for his passion for soccer in his free time, as well as for reading and spending time with his family and friends. He dedicates his knowledge of construction to low-income housing.

He has a master’s degree in finance and economics as well as in sustainable development from the INCAE; he is a civil engineer from the Latina University of Costa Rica and has a certificate as a professional in project management from the Project Management Institute (PMI).

Ronny is responsible for the overall results of the Garnier projects; he heads the managers of finance, engineering, sales-marketing, legal issues, accountability and RSC. He also directs technical teams of architects, engineers, contractors, and consultants in general. He is in charge of the financial model, budgeting, cash flow, and overall calendars for projects and strategic analysis, viability and due diligence studies for new investments.
`
      }
    },
    {
      name: 'Marcela Cano',
      picture: '/team/marcela.jpg',
      title: 'Gerente de sostenibilidad',
      description: `Es especialista en sostenibilidad, comunicación corporativa y relacionamiento estratégico; posee más de 15 años de experiencia en empresas multinacionales y cuenta con una amplia trayectoria en el sector construcción. Marcela Cano lidera la gerencia de sostenibilidad de Garnier & Garnier.
      
Es máster en Dirección Estratégica de Marketing de la Universidad Europea del Atlántico, es licenciada en Comunicación y Mercadeo de la Universidad Latina de Costa Rica, posee una Especialidad en Sostenibilidad del Instituto Tecnológico de Monterrey, México y otras tres especializaciones en emprendimiento e innovación social del Sirolli Institute, la Universidad de Leipzig e INCAE Business School.
      
En Garnier & Garnier es responsable del diseño de la estrategia corporativa de sostenibilidad, de garantizar que los desarrollos inmobiliarios de la compañía cumplan con los más altos estándares de sostenibilidad, de mitigar los impactos, gestionar los riesgos y agregar valor en los entornos donde opera la empresa y además dirigir la cultura de sostenibilidad.
      
Sus proyectos más emblemáticos han sido reconocidos en múltiples ocasiones por la American Chambers of Commerce, por su alto valor social y ambiental. Entre sus principales intereses está la innovación social, el emprendimiento y disfruta correr.

`,
      en: {
        title: 'Sustainability Manager',
        description: `
Marcela is a specialist in sustainability, corporate communication and strategic relations and has over 15 years of experience in multinational companies as well as extensive experience in the construction sector. Marcela Cano is Garnier & Garnier's sustainability manager.

She has a master´s degree in Strategic Marketing Management from the Universidad Europea del Atlántico, a degree in Communication and Marketing from the Universidad Latina de Costa Rica, a Specialization in Sustainability from the Instituto Tecnológico de Monterrey, Mexico, and three other specializations in entrepreneurship and social innovation from the Sirolli Institute, the University of Leipzig and INCAE Business School.

In Garnier & Garnier, she is responsible for designing the corporate sustainability strategy, ensuring that the company´s real estate developments meet the highest sustainability standards, mitigating impacts, managing risks and adding value in the environments where the company operates, as well as drive the culture of sustainability.

Marcela´s most emblematic projects have been recognized on multiple occasions by the American Chamber of Commerce for their high social and environmental value. Her main interests include social innovation, entrepreneurship and running.

`
      }
    },
    {
      name: 'Andrés Gómez',
      picture: '/team/andres.png',
      title: 'Gerente de Nuevos Negocios',
      description: `
  La gerencia de nuevos negocios es liderada por Andrés Gómez, quien se incorporó a Garnier en octubre del 2023. Fiel creyente del trabajo en equipo y que el éxito depende
  de todos. Es ingeniero electromecánico graduado de la Universidad Internacional de las Américas y máster en administración de empresas con énfasis en mercado de FUNDEPOS de Costa Rica.
      
  Posee más de 20 años de experiencia en el sector y con su incorporación al equipo Garnier, Andrés busca aportar en los procesos y las metodologías de trabajo que son
  pilares fundamentales en los proyectos de la compañía.Ser parte del crecimiento por medio de la innovación es sin duda un tema de gran interés.
      
  Entre sus objetivos espera traer nuevas oportunidades de negocio a la empresa, aumentar la cartera de clientes, apoyar al éxito de los proyectos en ejecución y a las empresas del grupo.
      
  En Garnier &amp; Garnier su rol está orientado a las nuevas inversiones de la compañía, además forma parte del Comité Ejecutivo de Inversión.
  Inicio su carrera profesional en Circuito, empresa de consultoría electromecánica, en 1997. Fue socio de la empresa y posteriormente la vendieron a Johnson Controls en el
  2015. Siendo parte del corporativo fue Director Asociado liderando un equipo de 150 personas y responsable de su estado de resultados.
      
   En su tiempo libre disfruta ir al gimnasio, así como jugar pádel y golf.
      `,
      en: {
        title: 'New Business Manager',
        description: `
  The new business department is headed by Andrés Gómez, who joined Garnier in October 2023. A staunch believer in teamwork and the idea that success depends on
    everyone, Andrés has a degree in electromechanical engineering from the Universidad Internacional de las Américas and a master’s in business administration with emphasis in
    marketing from FUNDEPOS de Costa Rica.
        
  With more than 20 years of experience in the sector, Andrés joined the Garnier team to contribute to the work methodologies and processes that form the cornerstones of the
    company’s projects. Being part of growth through innovation is unquestionably a topic of major interest to him.
        
  His goals include bringing new business opportunities to the company, expanding the customer portfolio, and contributing to the success of the group’s companies and ongoing projects.
        
  In addition to being on the executive investment committee, Andrés’s role in Garnier &amp; Garnier is aimed at new company investments.
        
  Andrés started his professional career in Circuito, an electromechanics consulting firm, in 1997. He was a partner in the company, which was later sold to Johnson Controls in
    2015. As part of the corporate team, he was associate director responsible for a team of 150 people and their results.
        
  In his free time, Andrés enjoys going to the gym and playing paddle and golf.

`
      }
    },
    {
      name: 'Marvin Mora',
      picture: '/team/marvin.png',
      title: 'Gerente de Recursos Humanos',
      description: `Como Gerente de Recursos Humanos de Garnier &amp; Garnier, Marvin Mora lidera
  dos pilares de nuestra organización: la gente y la cultura.
      
  Desde su ingreso, en 2013, ha impulsado el bienestar de las personas mediante la promoción de la salud integral, su felicidad y el desarrollo de
    competencias y habilidades.
      
  Es un fiel creyente de que los ambientes de trabajo retadores y amigables para las personas, donde cada persona se sienta valorada y comprometida con los
    objetivos de la empresa, generan resultados sobresalientes para ambas partes.Marvin es promotor de una cultura organizacional ética, orientada al logro y al
    bienestar de las personas.
      
  Se graduó como Licenciado en Administración de Empresas con énfasis en Gestión de Recursos Humanos de la Universidad Nacional de Costa Rica. Posee
    certificaciones en Gerencia de Felicidad y Cultura de Servicio.
      
  La fe es su gran aliada para cada acción que emprende. Ama a su familia y disfruta del deporte, particularmente las carreras de larga distancia, así como cocinar y
    estar en contacto con la naturaleza.

`,
      en: {
        title: 'Human Resource Manager',
        description: `
  As human resource manager for Garnier &amp; Garnier, Marvin Mora leads two pillars of our organization: people and culture.
        
  Since 2013, when he joined the company, he has fostered our people’s wellbeing by promoting their integral health, happiness, and competency and skill-building.
        
  Marvin firmly believes that challenging and people-friendly work environments, where every person feels valued and engaged in the company’s objectives, lead to outstanding
  results for both parties. He advocates an ethical organizational culture oriented towards personal achievement and wellbeing.
        
  With a licentiate degree in business administration with emphasis in human resource management from the Universidad Nacional de Costa Rica, Marvin holds certificates in
  happiness and service culture management.
        
  Faith is Marvin’s greatest ally in each of his undertakings. He loves being with his family and enjoys sports, especially long-distance racing, cooking, and being in contact with nature.

`
      }
    },

    {
      name: 'Erickson Alvarez',
      picture: '/team/erickson.png',
      title: 'Gerente de Tecnología de la Información',
      description: `Fuerte liderazgo en equipos tecnológicos y de transformación empresarial, habilidades sólidas en planificación y administración de recursos financieros y recursos humanos, y
  una amplia experiencia liderando equipos de tecnología en grandes empresas con presencia en Latinoamérica, son algunas de las características de Erickson, nuestro gerente de TI.
      
  Inició su carrera profesional en el Hospital Clínica Bíblica, como ingeniero junior en 1993, y previo a ingresar a Garnier, laboró en el Instituto Mixto de Ayuda Social (IMAS) como
  director ejecutivo del Sistema Nacional de Información y Registro Único de Beneficiarios del Estado (SINIRUBE).
      
  Desde agosto de 2022 forma parte de Garnier y tiene como parte de sus responsabilidades planificar y dirigir la Estrategia de Tecnología de Información para el
  Grupo con el objetivo de generar valor para el negocio, propiciar la modernización e innovación tecnológica en todo lo relacionado con las TIC y definir y monitorear el Plan de Continuidad de Negocios de la empresa.
      
  Es Magister en Administración de la Tecnología de la Información con Énfasis en Administración de Proyectos Informáticos de la Universidad Nacional De Costa Rica y
  Bachiller en Ingeniería Informática de la Universidad Central.
      
  Obtuvo el Premio Interamericano a la Innovación para la Gestión Pública Efectiva de la Organización de Estados Americanos (OEA), le gusta jugar tennis, ir al gimnasio, bailar,
  viajar y le encanta la playa.

`,
      en: {
        title: 'Information Technology Manager',
        description: `Strong leadership in technology teams and company transformation, solid skills in
  financial and human resource management and planning, and ample experience leading
  technology teams in large companies with offices in Latin America - these are some of the
  attributes of Erickson, our IT manager.
  
  Erickson started his professional career at the Hospital Clínica Bíblica as a junior engineer in 1993. Before joining Garnier, he worked at the Mixed Institute for Social Aid (IMAS) as
  executive director of the National System of Information and Single Registry of State Beneficiaries (SINIRUBE).
        
  A member of Garnier since August 2022, Erickson’s responsibilities include planning and leading the group’s information technology strategy to create value for the business,
  fostering modernization and technological innovation in all ITC-related aspects, and defining and monitoring the company’s business continuity plan.
        
  Erickson has a master’s in information technology management with emphasis in IT project management from the Universidad Nacional de Costa Rica and a bachelor’s
  degree in IT engineering from the Universidad Central.
        
  A recipient of the Inter-American Award on Innovation in Effective Public Management from the Organization of American States (OAS), he enjoys playing tennis, going to the
  gym, dancing, traveling, and spending time at the beach.

`
      }
    }
  ]
};

export const teamData = (language) => ({
  ...data,
  members: data.members.map((i) => Object.assign({}, i, i[language] || {}))
});
